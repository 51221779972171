
/**
 * State abbreviations
 */

export const stateAbbreviations = {
	'Colorado'  : 'CO',
	'Idaho'     : 'ID',
	'Montana'   : 'MT',
	'Nevada'    : 'NV',
	'New Mexico': 'NM',
	'Utah'      : 'UT',
	'Wyoming'   : 'WY'
};


/**
 * Monthly values
 */

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


/**
 * Category parent-level filters
 */

export const parentControls = ['wildlife_overall', 'wildlife_monthly', 'wildlife_winter'];


/**
 * Wildlife filter types/tile properties
 */

export const featureTypeProperties = {
	wildlife_overall: 'isOverall',
	wildlife_monthly: 'isMonthly',
	wildlife_winter: 'isWinterOnly',
	wildlife_range: 'isRange',
	wildlife_concentration: 'isConcentration',
	wildlife_mineral_lick: 'isMineral',
	wildlife_water: 'isWater',
	wildlife_migration: 'isMigration',
	wildlife_roost_site: 'isRoostSite',
	wildlife_feed_ground: 'isFeedGround'
};