
export default function (queryValue, alpha) {
	// set module.active property
	if (queryValue > 0) {
		this.active = true;
	}

	// set control.active property of each control
	this.controls = this.controls.reduceRight((result, item, i) => {
		if (queryValue >= 2**i) {
			queryValue -= 2**i;
			item.active = true;
		} else {
			item.active = false;
		}
		
		result.unshift(item);
		return result;
	}, []);

	// set module opacity
	this.opacity = parseFloat(alpha);
}