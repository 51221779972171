
const initialState = {
	activeFeatures: null,
	pendingFeatures: null
};

function activeFeaturesReducer(state = initialState, action) {
	switch (action.type) {
		case 'CLEAR_ALL_FEATURES':
			return {
				...state,
				activeFeatures: null,
				pendingFeatures: null
			};
		case 'SET_ACTIVE_FEATURES':
			return {...state, activeFeatures: action.payload};
		case 'SET_PENDING_FEATURES':
			return {...state, pendingFeatures: action.payload};
		default:
			return state;
	}
}

export default activeFeaturesReducer;