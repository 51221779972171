import chroma from 'chroma-js';
import {huntingGradientColors} from '../config/hunting';
import cloneDeep from 'lodash/cloneDeep';

const scale = chroma.scale(huntingGradientColors).mode('lrgb');


/**
 * Get match expression for paint properties (fill-color, text-color, etc)
 * Match UnitID to value in color scale based on analytic data values
 * Used to set fill-color property for hunt_units_fill_data layer
 */

export function getAnalyticPaintExpression(analytic = null, data, bounds = []) {
	// if analytic is null or relevant analytic field is null in data, return transparent
	if (analytic === null || !data || data.every(item => item[analytic.data_table_col] === null)) {
		return 'rgba(0,0,0,0)';
	}

	// normalize data values - remove duplicates, convert percentage fields
	data = resolveDuplicates(cloneDeep(data), analytic.data_table_col);

	// make color scale relative to min/max values for this analytic
	scale.domain([
		bounds[`min_${analytic.data_table_col}`],
		bounds[`max_${analytic.data_table_col}`],
	]);


	const expression = data.reduce((expression, unit) => {
		const value = unit[analytic.data_table_col];

		if (unit.unit_id && !isNaN(value)) {			
			expression.push(
				unit.unit_id,
				value === -1
					? 'rgba(160,160,160,0.6)' // gray for -1
					: `rgba(${scale(parseFloat(value)).rgb().join(',')},0.6)`
			);
		}

		return expression;
	}, ['match', ['get', 'UnitID']]);

	// fallback fill
	expression.push('rgba(0,0,0,0)');

	return expression;
}


/**
 * Get text-field match expression
 * Match UnitId to analytic value
 * Used to set text-field property for hunt_units_analytic_value layer
 */

export function getAnalyticSymbolExpression(analytic = null, data) {
	// if analytic is null or relevant analytic field is null in data, return empty string
	if (analytic === null || !data || data.every(item => item[analytic.data_table_col] === null)) {
		return '';
	}

	// normalize data values - remove duplicates, convert percentage fields, convert to strings
	data = resolveDuplicates(cloneDeep(data), analytic.data_table_col).map(item => {
		if (item[analytic.data_table_col] !== null && item[analytic.data_table_col] !== undefined) {
			item[analytic.data_table_col] = item[analytic.data_table_col].toString()
		}

		return item;
	});

	const expression = data.reduce((expression, unit) => {
		if (unit.unit_id && !isNaN(unit[analytic.data_table_col])) {
			let displayValue = unit[analytic.data_table_col];

			if (displayValue === '-1') {
				displayValue = 'New Hunt';
			} else if (analytic.is_percentage) {
				displayValue = `${getPercentage(displayValue)}%`;
			}

			expression.push(
				unit.unit_id,
				`(${displayValue})`
			);
		}

		return expression;
	}, ['match', ['get', 'UnitID']]);

	// fallback value
	expression.push('');

	return expression;
}


/**
 * Resolve duplicates in data by taking item with higher value
 */

export function resolveDuplicates(data, field) {
	return data.reduce((results, unit) => {
		const existingUnit = results.find(result => result.unit_id === unit.unit_id);

		if (!existingUnit) {
			results.push(unit);
			return results;
		}

		if (existingUnit[field] === null || isNaN(existingUnit[field]) || (existingUnit[field] < unit[field])) {
			// remove existing item with null value or value < current item value
			results = results.filter(item => item.unit_id !== unit.unit_id);
			results.push(unit);
		}

		return results;
	}, []);
}


/**
 * Format percentage value
 */

export function getPercentage(decimal) {
	const value = decimal * 100;
	return value.toFixed(value >= 10 ? 1 : 2);
}


/**
 * Populate <males> and <trophy_metric> placeholders
 * with properties from selected species
 */

export function populateMetrics(options, species) {
	const doPopulateMetrics = analytic => {
		// <males>
		analytic.display_name = analytic.display_name.replace(
			'<males>',
			species.males
		);

		// <trophy_metric>
		analytic.display_name = analytic.display_name.replace(
			'<trophyMetric>',
			species.trophy_metric
		);

		return analytic;
	}

	// if argument is array, populate each element
	if (Array.isArray(options)) {
		return options.map(doPopulateMetrics);
	}

	return doPopulateMetrics(options);
}