import getBaseExpression from '../utils/getMapModuleQueryExpression';
import {HuntingApi, getAnalyticConditions, getMaxParamSequence} from '../hunting-api';
import {getAnalyticPaintExpression, getAnalyticSymbolExpression} from '../utils/hunting';
import store from '../../store';
import {applyHuntSettings as applyHuntSettingsAction, setFillData} from '../../actions/hunting';

export function getHuntingModuleExpression() {
	const baseExpression = getBaseExpression.call(this);
	const unitType = store.getState().hunting.selectedHuntType;

	if (unitType === null) {
		return null;
	}

	if (baseExpression === null) {
		return baseExpression;
	}

	return `${baseExpression}:${unitType.layer_id}`;
}

export function getHuntSettings() {
	const hunting = store.getState().hunting;

	const stateID = hunting.selectedHuntState ? hunting.selectedHuntState.id : null;
	const speciesID = hunting.selectedHuntSpecies ? hunting.selectedHuntSpecies.id : null;
	const unitType = hunting.selectedHuntType;
	const huntParams = hunting.huntParams;
	const analytic = hunting.selectedAnalytic;
	const hasAnalyticData = hunting.analyticData !== null;

	return {
		stateID,
		speciesID,
		unitType,
		analytic,
		huntParams,
		hasAnalyticData
	};
}

export async function applyHuntSettings(settings) {
	let {
		stateID,
		speciesID,
		unitType,
		analytic,
		huntParams,
		hasAnalyticData
	} = settings;

	let state, species = null;

	state = store.getState().staticData.states.find(
		state => state.id === stateID
	);

	if (state) {
		species = state.species.find(
			species => species.id === speciesID
		);
	}

	const payload = {
		selectedHuntState: state || null,
		selectedHuntSpecies: species || null,
		selectedHuntType: (state !== null && species !== null) ? unitType : null,
		selectedAnalytic: analytic
	};

	if (analytic && huntParams && huntParams.length) {
		payload.huntParams = huntParams;
	}

	store.dispatch(applyHuntSettingsAction(payload));

	const profile = store.getState().profile.profile;

	let analyticData = {data: null, bounds: null};

	if (profile && profile.has_subscription && hasAnalyticData) {
		const conditions = getAnalyticConditions(huntParams, analytic);
		const sequence = getMaxParamSequence(huntParams, analytic);

		analyticData = await HuntingApi.getAnalyticData(
			state.id,
			species.id_state_species,
			unitType ? unitType.value : null,
			analytic,
			sequence,
			conditions
		);
	}

	store.dispatch(setFillData(analyticData));
}

export function onStyleChange() {
	const map = store.getState().mapbox.instance;

	const {
		selectedAnalytic,
		analyticData,
		analyticDataBounds
	} = store.getState().hunting;

	const analyticPaintExpression = getAnalyticPaintExpression(
		selectedAnalytic,
		analyticData,
		analyticDataBounds
	);

	const mapLayers = map.getStyle().layers;

	if (!mapLayers.find(layer => layer.id ==='hunting_analytics_fill')) {
		return;
	}

	map.setPaintProperty(
		'hunting_analytics_fill',
		'fill-color',
		analyticPaintExpression
	);

	map.setPaintProperty(
		'hunting_analytics_label',
		'text-color',
		analyticPaintExpression
	);

	map.setLayoutProperty(
		'hunting_analytics_label',
		'text-field',
		getAnalyticSymbolExpression(
			selectedAnalytic,
			analyticData
		)
	);	
}