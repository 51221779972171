import getMapModuleQueryExpression from '../explore/utils/getMapModuleQueryExpression';
import moduleIsVisible from '../explore/utils/moduleIsVisible';
import applyStandardQueryExpression from '../explore/utils/applyStandardQueryExpression';

const initialState = {};

const getModuleExpressions = {
	roads_and_trails: require('../explore/map-modules/roads_and_trails').getRoadsAndTrailsModuleExpression,
	wildlife: require('../explore/map-modules/wildlife').getWildlifeModuleExpression,
	hunting: require('../explore/map-modules/hunting').getHuntingModuleExpression
};

const applyModuleExpressions = {
	roads_and_trails: require('../explore/map-modules/roads_and_trails').applyRoadsAndTrailsModuleExpression,
	wildlife: require('../explore/map-modules/wildlife').applyWildlifeModuleExpression,
};

const getModuleSettings = {
	hunting: require('../explore/map-modules/hunting').getHuntSettings
};

const applyModuleSettings = {
	hunting: require('../explore/map-modules/hunting').applyHuntSettings
};

const styleChangeHandlers = {
	hunting: require('../explore/map-modules/hunting').onStyleChange
};

function initializeMapModules(modules) {
	return {
		...Object.keys(modules).reduce((results, id) => {
			const module = modules[id];

			// Main module toggle value
			module.active = false;

			// Default opacity slider position
			module.opacity = module.defaultOpacity;

			// Create active properties on each control
			module.controls = module.controls.map(control => {
				control.active = (control.default || false);
				return control;
			});
			
			// Method to get query params for mapbox style url based on current map state
			// Fall back to base getMapModuleQueryExpression method if no specific decorator
			// is defined for this map module
			module.getExpression = (getModuleExpressions[id] || getMapModuleQueryExpression)
			
			// Method to apply a query expression from the style url to this module
			module.applyExpression = (applyModuleExpressions[id] || applyStandardQueryExpression);
			
			// Method to determine if there is a style query for this module
			// that will actually display visible results on the map
			// The green circle indicator in the main panel navigation makes use of this
			module.isVisible = function() {
				return moduleIsVisible(this);
			}
			
			// Method to get any extra settings required by a module when saving a map view
			module.getSettings = (getModuleSettings[id] || function() {});
			
			// Method to apply any extra saved map settings to this module
			// For example, panel dropdown values
			module.applySettings = (applyModuleSettings[id] || function() {});

			// Method called any time a style is changed
			module.onStyleChange = (styleChangeHandlers[id] || function() {});

			return {
				...results,
				[module.id]: module
			};
		}, {})
	};
}

export default function(state = initialState, action) {
	switch (action.type) {
		case 'INITIALIZE_MAP_MODULES': {
			return initializeMapModules(action.payload);
		}
		case 'SET_MAP_MODULE': {
			return {
				...state,
				[action.payload.id]: action.payload
			};
		}
		default: {
			return state;
		}
	}
}