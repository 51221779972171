const initialState = {
	loaded: false,

	states: null,
	activities: null,
	ages: null,
	campingTypes: null,
	genders: null,
	months: null,
	ratings: null,
	roadsAndTrailsTypes: null,
	species: null,
	wildlife: null
}

export default function userReducer(state = initialState, action) {
	switch(action.type) {
		case 'LOAD_STATIC_DATA_SUCCESS':
			return {
				...state,
				loaded: true,
				...action.data
			};
		case 'SET_STATES':
			return {
				...state,
				states: action.payload
			};
		default: 
			return state;
	}
}