export default function getMapModuleQueryExpression() {
	const module = this;
	let value = 0;

	if (module.active === true) {
		value = module.controls.reduce((value, control, i) => {
			if (!control.active) {
				return value;
			}

			return (value + (2**i));
		}, 0);
	}

	return value === 0
		? null
		: `${module.id}=${value}:${module.opacity}`;
}