import React, { lazy } from 'react';
import './Landing.scss';
import Header from '../../components/Header/Header';
import { Helmet } from 'react-helmet';
import Adventures from '../../components/Adventures/Adventures';
import {getQueryParam} from '../../utils/helperFunctions';
import Butter from 'buttercms';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ScrollTrigger from '../../components/ScrollTrigger/ScrollTrigger';
const InfoModal = lazy(() => import('../../components/InfoModal/InfoModal'));
const Footer = lazy(() => import('../../components/Footer/Footer'));
const VideoCta = lazy(() => import('../../components/VideoCta/VideoCta'));
const MobileCta = lazy(() => import('../../components/MobileCta/MobileCta'));
const InteriorForm = lazy(() => import('../../components/Interior/Form/Form'));
const Partners = lazy(() => import('../../components/Partners/Partners'));
const Reviews = lazy(() => import('../../components/Reviews/Reviews'));
const ProCta = lazy(() => import('../../components/ProCta/ProCta'));
const FeaturedArticles = lazy(() => import('../../components/FeaturedArticles/FeaturedArticles'));

const butter = Butter(process.env.REACT_APP_BUTTER_KEY);

class Landing extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			content: null,
			loading: true,
			headerTheme: 'light'
		};
	}

	getPageContent() {
		const previewMode = getQueryParam('preview');

		return butter.page.retrieve(
			'*',
			'landing-v2',
			{preview: previewMode}
		).then(response => {
			return response.data.data.fields;
		});
	}

	async componentDidMount() {
		window.scrollTo(0, 0);

		try {
			const content = await this.getPageContent();

			this.setState({
				content,
				loading: false
			});

		} catch (error) {
			console.log(error);
			this.setState({
				content: {},
				loading: false
			});
		}
	}

	handleScrollTrigger = status => {
		const theme = (status.currentPosition === 'above' ? 'dark' : 'light');
		this.setState({headerTheme: theme});
	}

	renderStaticContent() {
		const {content} = this.state;

		if (!content) return null;

		return (
			<React.Fragment>
				<VideoCta {...content} />
				<ProCta {...content} />
				<MobileCta {...content} />
				<FeaturedArticles />
				<Reviews reviews={content.reviews} />
				<Partners partners={content.partners} />
				<InteriorForm />
			</React.Fragment>
		);
	}

	renderHelmet() {
		const {content} = this.state;

		if (!content) return null;

		return (
			<Helmet>
				<title>{this.state.content.seo_title}</title>
				<meta property="description" content={this.state.content.seo_meta_description}/>
				<meta property="og:type" content="landing" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:site" content="@getOutly‬" />
				<meta name="twitter:creator" content="@getOutly" />
				<meta property="og:url" content="https://www.outly.com/outly" />
				<meta property="og:title" content={this.state.content.seo_title} />
				<meta property="og:description" content={this.state.content.seo_meta_description} />
				<meta property="og:image" content={this.state.content.mobile_app_cta_background} />
			</Helmet>
		)
	}

	render() {
		const footerLinks = [
			{
				name: 'About Us',
				to: '/about-us'
			},
            {
                name: 'Contact Us',
                to: '/contact'
			}
        ]
		return (
			<div className="oa-landing">
				<ScrollTrigger onChange={this.handleScrollTrigger} className='oa-landing-scroll-trigger' />
				<Header theme={this.state.headerTheme} isOA={false} />
				{this.renderHelmet()}
				<div className="oa-landing-content">
					<Adventures headline={this.state.content ? this.state.content.headline : ''} />
					{this.renderStaticContent()}
				</div>
				<Footer 
					className="oa-footer-dark"
					pageLinks={footerLinks} />
				<InfoModal />
			</div>
		);
	}
}

export default Landing;