import getBaseExpression from '../utils/getMapModuleQueryExpression';
import applyStandardQueryExpression from '../utils/applyStandardQueryExpression';
import {months as monthAbbr} from '../config/wildlife';
import store from '../../store';
import {
	setSelectedWildlifeState,
	setSelectedWildlifeSpecies,
	setSelectedMonth
} from '../../actions/wildlife';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function getWildlifeModuleExpression() {
	const baseExpression = getBaseExpression.call(this);

	if (baseExpression === null) {
		return baseExpression;
	}
	
	const wildlife = store.getState().wildlife;
	const state = wildlife.selectedWildlifeState;
	const species = wildlife.selectedWildlifeSpecies;
	const month = wildlife.selectedMonth;

	let expression = baseExpression;

	if (state !== null) {
		expression += `:${state.id}`;
		
		if (species !== null) {
			expression += `:${species.id}`;

			if (month !== null) {
				expression += `:${monthAbbr.indexOf(month)}`;
			}
		}
	}

	return expression;
}

export function applyWildlifeModuleExpression(queryValue, alpha, stateID, speciesID, month) {
	applyStandardQueryExpression.call(this, queryValue, alpha);

	if (stateID === undefined) {
		return;
	}

	const states = store.getState().staticData.states;
	const selectedState = states.find(state => state.id === parseInt(stateID));

	if (selectedState !== undefined) {
		store.dispatch(setSelectedWildlifeState(selectedState));
	}
	
	if (speciesID !== undefined) {
		const selectedSpecies = selectedState.species.find(species => species.id === parseInt(speciesID));

		if (selectedSpecies !== undefined) {
			store.dispatch(setSelectedWildlifeSpecies(selectedSpecies));
		}
	}

	if (month !== undefined) {
		store.dispatch(setSelectedMonth(months[month]));
	}
}