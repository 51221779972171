
const initialState = {
	showExportDialog: false
};

function gpxReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_SHOW_EXPORT_DIALOG':
			return {
				...state,
				showExportDialog: action.payload
			};
		default:
			return state;
	}
}

export default gpxReducer;