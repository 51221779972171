
const initialState = {
  selectedHuntState: null,
  selectedHuntSpecies: null,
  selectedHuntType: null,
  selectedAnalytic: null,
  huntParams: [],
  analyticData: null,
  analyticDataBounds: null,
  analytics: null,
}

function huntingReducer(state = initialState, action) {
  switch(action.type) {
    case 'APPLY_HUNT_SETTINGS':
      return {
        ...state,
        ...action.payload
      };
    case 'SET_SELECTED_HUNT_STATE':
      return {
        ...state,
        selectedHuntState: action.payload,
        huntParams: [],
        analyticData: null,
        analyticDataBounds: null
      };
    case 'SET_SELECTED_HUNT_SPECIES':
      return {
        ...state,
        selectedHuntSpecies: action.payload,
        huntParams: [],
        analyticData: null,
        analyticDataBounds: null
      };
    case 'SET_SELECTED_HUNT_TYPE':
      return {
        ...state,
        selectedHuntType: action.payload,
        huntParams: [],
        analyticData: null,
        analyticDataBounds: null
      };
    case 'SET_HUNT_PARAMS':
      return {
        ...state,
        huntParams: action.payload
      };
    case 'SET_FILL_DATA':
      const data = action.payload.data;
      const bounds = action.payload.bounds;

      return {
        ...state,
        analyticData: data,
        analyticDataBounds: bounds
      };
    case 'SET_HUNT_ANALYTIC':
      return {
        ...state,
        selectedAnalytic: action.payload
      };
    case 'SET_HUNT_ANALYTICS':
      return {
        ...state,
        analytics: action.payload || []
      };
    default:
      return state;
  }
}

export default huntingReducer;