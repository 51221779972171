const initialState = {
	instance: null
};

function mapboxReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_MAPBOX_INSTANCE': {
			return {
				...state,
				instance: action.payload
			};
		}
		default: {
			return state;
		}
	}
}

export default mapboxReducer;