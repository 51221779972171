import ActivityFilter from '../../activity-filter';

class VehicleTypeFilter extends ActivityFilter {

	/**
	 * Filter slug (query param in resulting url)
	 */
	
	slug = 'trailsAndRoadsType';

	/**
	 * Filter label (e.g. 'What would you like to <b>hunt</b>?')
	 */
	
	label = 'What type of <b>vehicle</b>?';

	/**
	 * Filter options may depend on previously selected filter values
	 * This method lets you return what you want based on previous selections
	 * or return different options based on screen width (e.g. paginated or not)
	 */
	
	getOptions = (data, page, currentFilters) => {
		const types = ['Dirt Bike', 'ATV', '4WD', '2WD'];

		return data.roadsAndTrailsTypes.filter(type => {
			return types.includes(type.value);
		});
	}
}

export default VehicleTypeFilter;