import ActivityFilters from '../';
import VehicleTypeFilter from './filters/vehicle-type';

class RidingActivityFilters extends ActivityFilters {

	/**
	 * Activity name
	 */
	
	name = 'ride'

	/**
	 * Activity label (e.g. 'Plan my <b>hunt</b>')
	 */
	
	label = 'Discover new <b>rides</b>';

	/**
	 * Activity panel router endpoint
	 */

	panelDestination = 'trails-and-roads';

	/**
	 * Activity filters
	 */
	
	filters = [
		new VehicleTypeFilter()
	];

	/**
	 * Whether or not to include a geocoder in this filter sequence
	 */
	
	hasGeocoder = true;

	/**
	 * Get label text for geocoder input
	 */
	
	getGeocoderLabel = (currentFilters) => {
		return 'Discover new <b>rides</b> near:';
	}

	/**
	 * Activity filters getter:
	 * Subsequent filters may depend on previously selected filter values
	 * This method lets you return what you want based on current selections
	 */
	
	getFilters = (currentFilters) => {
		return currentFilters;
	}	
}

export default RidingActivityFilters;