
const initialState = {
	panelActive: false,
	globalNavActive: false
};

function layersPanelReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_PANEL_ACTIVE':
			return {
				...state,
				globalNavActive: false,
				panelActive: action.payload
			};
		case 'SET_GLOBAL_NAV_ACTIVE':
			return {
				...state,
				globalNavActive: action.payload
			};
		default:
			return state;
	}
}

export default layersPanelReducer;