import getBaseExpression from '../utils/getMapModuleQueryExpression';
import applyStandardQueryExpression from '../utils/applyStandardQueryExpression';
import store from '../../store';
import {setRoadsActivityIndex} from '../../actions/roadsActivityIndex';

export function getRoadsAndTrailsModuleExpression() {
	const activityIndex = store.getState().activeRoadsActivity;
	const baseExpression = getBaseExpression.call(this);

	if (baseExpression === null) {
		return baseExpression;
	}

	return `${baseExpression}:${activityIndex}`;
}

export function applyRoadsAndTrailsModuleExpression(queryValue, alpha, activityIndex = 0) {
	applyStandardQueryExpression.call(this, queryValue, alpha);
	store.dispatch(setRoadsActivityIndex(parseInt(activityIndex)));
}