/* eslint no-throw-literal: 0 */

import React from 'react';
import './HarvestTool.scss';
import fetch from 'isomorphic-fetch';
import {apiBaseUrl} from '../../config/config';

export default class HarvestTool extends React.Component {
	state = {
		states: [],
		species: [],
		unitTypes: [],
		huntSelections: [],

		selectedState: '',
		selectedSpecies: '',
		selectedUnitType: '',

		sequence: 1,
		data: null,
		message: '',
		metadata: '',

		analytic: 'harvest'
	};

	async componentDidMount() {
		this.setState({states: await this.getStates()});
	}

	getStates() {
		return fetch(
			`${apiBaseUrl}/states`
		).then(response => response.json());
	}

	getUnitTypes(stateID, speciesID) {
		return fetch(
			`${apiBaseUrl}/unit-layers/${stateID}/${speciesID}`
		).then(response => response.json());
	}

	getHuntSelection(stateID, stateSpeciesId, huntType, sequence, conditions = {}) {
		let selectedAnalytic;

		/**
		 * Backwards compatibility with old analytics
		 */
		
		switch (this.state.analytic) {
			case 'harvest':
				selectedAnalytic = {id: 2, data_table: 'harvest_prediction', data_table_col: 'prediction', display_name: 'Predicted Harvest Rate', hunt_selections_col: 'for_harvest', is_percentage: true, sort_order: 2, unit_layers_col: 'harvest_exists'};
				break;
			case 'draw':
				selectedAnalytic = {id: 1, data_table: 'draw_odds', data_table_col: 'odds_percent', display_name: 'Draw Odds', hunt_selections_col: 'for_draw', is_percentage: true, sort_order: 1, unit_layers_col: 'draw_exists'};
				break;
			default:
				selectedAnalytic = null;
		}

		return fetch(
			`${apiBaseUrl}/hunting-param`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					stateID: stateID,
					stateSpeciesID: stateSpeciesId,
					huntType: huntType === 'null' ? null : huntType,
					sequence: sequence,
					conditions: conditions,
					analytic: selectedAnalytic
				})
			}
		).then(response => {
			return response.json();
		}).then(data => {
			if (data.error) {
				throw data.error;
			}

			if (data.hasOwnProperty('sequenceComplete')) {
				this.setState({data: data.data.data, metadata: data.metadata.query});
				throw 'sequence end';
			} else {
				const state = {
					data: null,
					metadata: JSON.stringify(data.metadata.conditions)
				};

				if (!data.selection.options.length) {
					state.message = `
						No options found for ${data.selection.column_name}
						state: ${stateID},
						stateSpecies: ${stateSpeciesId},
						huntType: ${huntType}
						sequence: ${sequence}
						conditions: ${JSON.stringify(conditions)}
						analytic: ${this.state.analytic}
					`;
				}

				this.setState(state);
			}

			return data.selection;
		});
	}

	findInCollection(collection, property, value) {
		return this.state[collection].find(item => (
			item[property].toString() === value.toString()
		));
	}

	handleAnalyticChange(e) {
		const value = e.target.value;

		this.setState({
			huntSelections: [],

			sequence: 1,

			data: null,
			message: '',
			metadata: '',

			analytic: value
		}, () => {
			if (this.state.selectedUnitType) {
				this.handleSpeciesChange({target: {value: this.state.selectedSpecies}});
			}
		});		
	}

	async handleStateChange(e) {
		const value = e.target.value;
		const selectedState = this.state.states.find(
			state => state.id === parseInt(e.target.value)
		);

		this.setState({
			selectedState: value,
			selectedSpecies: '',
			selectedUnitType: '',

			species: selectedState.species,
			huntSelections: [],

			sequence: 1,

			data: null,
			message: '',
			metadata: ''
		});
	}

	async handleSpeciesChange(e) {
		const value = e.target.value;
		const unitTypes = await this.getUnitTypes(
			this.state.selectedState,
			e.target.value
		).then(unitTypes => {
			console.log(unitTypes);
			return unitTypes.filter(unitType => unitType.harvest_exists);
		});

		let message = '';

		if (!unitTypes.length) {
			message = 'No unit types where harvest_exists';
		}

		this.setState({
			selectedSpecies: value,
			selectedUnitType: '',

			unitTypes: unitTypes,
			huntSelections: [],

			sequence: 1,

			data: null,
			message: message,
			metadata: ''
		});
	}

	async handleUnitTypeChange(e) {
		const value = e.target.value;
		
		const state = {
			selectedUnitType: value,
			sequence: 1
		};

		const currentSpecies = this.findInCollection(
			'species',
			'id',
			this.state.selectedSpecies
		);

		const currentUnitType = this.findInCollection(
			'unitTypes',
			'id',
			state.selectedUnitType
		);

		let selection;
		
		try {
			selection = await this.getHuntSelection(
				this.state.selectedState,
				currentSpecies.id_state_species,
				currentUnitType.value,
				state.sequence
			);
		} catch (error) {
			console.log(error);
			return;
		}

		state.huntSelections = [{
			...selection,
			value: ''
		}];

		state.data = null;
		state.message = '';
		state.metadata = '';

		this.setState(state, () => {
			console.log(this.state);
		});
	}

	async handleSelectionChange(selection, value) {
		const selections = this.state.huntSelections.map(item => {
			console.log(item);
			if (item.id === selection.id) {
				item.value = value;
			}

			return item;
		}).filter(item => item.sequence <= selection.sequence);

		const state = {
			huntSelections: selections,
			sequence: selection.sequence + 1
		};

		const currentSpecies = this.findInCollection(
			'species',
			'id',
			this.state.selectedSpecies
		);

		const currentUnitType = this.findInCollection(
			'unitTypes',
			'id',
			this.state.selectedUnitType
		);

		const conditions = state.huntSelections.reduce((conditions, selection) => {
			conditions[selection.selection_source][selection.column_name] = selection.value === 'null' ? null : selection.value;
			return conditions;
		}, {
			licenses: {},
			draw_odds: {}
		});
		
		let nextSelection;

		try {
			nextSelection = await this.getHuntSelection(
				this.state.selectedState,
				currentSpecies.id_state_species,
				currentUnitType.value,
				state.sequence,
				conditions
			);

			state.huntSelections.push({
				...nextSelection,
				value: ''
			});

			state.data = null;
			state.message = null;

		} catch (error) {
			console.log(error);
		}

		this.setState(state);
	}

	renderModeSelect() {
		const options = [
			{label: 'Harvest Prediction', value: 'harvest'},
			{label: 'Draw Odds', value: 'draw'}
		];

		return (
			<select value={this.state.analytic} onChange={this.handleAnalyticChange.bind(this)}>
				{options.map((option, o) => (
					<option key={o} value={option.value}>{option.label}</option>
				))}
			</select>
		);
	}

	renderStateSelect() {
		const {states} = this.state;

		if (!states.length) return null;

		return (
			<select value={this.state.selectedState} onChange={this.handleStateChange.bind(this)}>
				<option value=''>Select State</option>
				{states.map((state, s) => (
					<option key={s} value={state.id}>{state.value}</option>
				))}
			</select>
		);
	}

	renderSpeciesSelect() {
		const {species} = this.state;

		if (!species.length) return null;

		return (
			<select value={this.state.selectedSpecies} onChange={this.handleSpeciesChange.bind(this)}>
				<option value=''>Select Species</option>
				{species.filter(species => species.layer_count > 0).map((species, s) => (
					<option key={s} value={species.id}>{species.value}</option>
				))}
			</select>
		);	
	}

	renderUnitTypeSelect() {
		const {unitTypes} = this.state;

		if (!unitTypes.length) return null;

		return (
			<select value={this.state.selectedUnitType} onChange={this.handleUnitTypeChange.bind(this)}>
				<option value=''>Select Unit Type</option>
				{unitTypes.map((unitType, u) => (
					<option key={u} value={unitType.id}>
						{`${(unitType.value || 'Unnamed')} (${unitType.layer_id})`}
					</option>
				))}
			</select>
		);
	}

	renderHuntSelections() {
		const {huntSelections} = this.state;

		if (!huntSelections.length) return null;

		return (
			huntSelections.map((selection, s) => {
				return (
					<select key={s} value={selection.value} onChange={e => this.handleSelectionChange(selection, e.target.value)}>
						<option value=''>Select {selection.display_text}</option>
						{selection.options.map((option, o) => (
							<option key={o} value={option[selection.column_name]}>
								{option[selection.column_name] || 'null'}
							</option>
						))}
					</select>
				);
			})
		);
	}

	renderData() {
		if (!this.state.data || !this.state.data.length) return null;

		const data = this.state.data.map(row => {
			if (Object.keys(row).every(field => row[field] === null)) {
				return row;
			}

			if (this.state.data.filter(item => item.unit_id && item.unit_id === row.unit_id).length > 1) {
				row.duplicate = true;
			} else {
				row.duplicate = false;
			}

			return row;
		});


		return (
			<table style={{color: '#FFF', width: '100%', fontSize: '12px', margin: '32px 0'}}>
				<thead>
					<tr>
						{Object.keys(data[0]).map(field => (
							<th style={{padding: '2px 4px'}} key={field}>{field}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.map((row, r) => {
						const style = {
							padding: '4px',
							border: '1px solid #CCC',
						};

						if (row.duplicate) {
							style.backgroundColor = '#EEE';
							style.color = '#000';
						}
					
						return (
							<tr key={r}>
								{Object.keys(row).map((field, f) => (
									field === 'prediction'
										? <td style={style} key={f}>{row[field] === null ? 'null' : row[field].toFixed(2)}</td>
										: <td style={{...style, whiteSpace: field === 'notes' ? 'normal' : 'nowrap' }} key={f}>{row[field] === null ? 'null' : row[field]}</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	render() {
		return (
			<div className="harvest-tool-container">
				{this.renderModeSelect()}
				{this.renderStateSelect()}
				{this.renderSpeciesSelect()}
				{this.renderUnitTypeSelect()}
				{this.renderHuntSelections()}
				<p style={{color: '#FFF', margin: '24px 0 0 0', whiteSpace: 'pre'}}>{this.state.message}</p>
				<p style={{color: '#FFF', margin: '24px 0 0 0', fontSize: '0.75em'}}>{this.state.metadata}</p>
				{this.renderData()}
			</div>
		);
	}
}