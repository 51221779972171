import React from 'react';
import './CompanySection.scss';
import { NavLink } from 'react-router-dom';

const CompanySection = props => (
    <section className='company-section' style={{backgroundImage: `url(${props.info.background_image})`}}>
        <div className='company-section-content'>
            <h5>{props.info.cta_headline}</h5>
            <h4 dangerouslySetInnerHTML={{__html: props.info.cta_content}} />
            <NavLink to={`/${props.info.cta_button_slug}`} className='oa-button'>
                {props.info.cta_button_text}
            </NavLink>
        </div>
    </section>
);

export default CompanySection;