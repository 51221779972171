import ActivityFilters from '../';
import ActivityFilter from '../activity-filter';

import CampingTypeFilter from './filters/camping-type';
import CampgroundsEquipmentFilter from './filters/campgrounds-equipment';
import CampgroundsWaterFilter from './filters/campgrounds-water';
import DispersedVehicleTypeFilter from './filters/dispersed-vehicle-type';
import DispersedEquipmentFilter from './filters/dispersed-equipment';
import DispersedWaterFilter from './filters/dispersed-water';

class CampingActivityFilters extends ActivityFilters {

	/**
	 * Activity name
	 */
	
	name = 'camp'

	/**
	 * Activity label (e.g. 'Plan my <b>hunt</b>')
	 */
	
	label = 'Find a <b>campsite</b>';

	/**
	 * Activity panel router endpoint
	 */

	panelDestination = 'camping';

	/**
	 * Activity filters
	 */
	
	filters = [
		new CampingTypeFilter(),
		new CampgroundsEquipmentFilter(),
		new CampgroundsWaterFilter(),
		new DispersedVehicleTypeFilter(),
		new DispersedEquipmentFilter(),
		new DispersedWaterFilter()
	];

	/**
	 * Whether or not to include a geocoder in this filter sequence
	 */
	
	hasGeocoder = true;

	/**
	 * Get label text for geocoder input
	 */
	
	getGeocoderLabel = (currentFilters) => {
		const typeFilter = ActivityFilter.getFilterBySlug(currentFilters, 'campingType');

		if (!typeFilter) {
			return '';
		}

		switch (typeFilter.value.slug) {
			case 'campgrounds':
				return 'Find <b>campgrounds</b> near:';
			case 'dispersed':
				return 'Find <b>dispersed campsites</b> near:';
			case 'cabin':
				return 'Find <b>Cabins, Yurts, and Lookouts</b> near:';
			default:
				return '';
		}
	}

	/**
	 * Activity filters getter:
	 * Subsequent filters may depend on previously selected filter values
	 * This method lets you return what you want based on current selections
	 */
	
	getFilters = (currentFilters) => {
		const campingTypeFilter = currentFilters.find(filter => {
			return filter.slug === 'campingType';
		});

		if (!campingTypeFilter.value) {
			return [campingTypeFilter];
		}

		// return campgrounds/dispersed filters based on campingTypeFilter value
		switch (campingTypeFilter.value.slug) {
			case 'cabin':
				// for cabins, return only type filter and proceed directly to geocoder
				return [campingTypeFilter];
			case 'campgrounds':
				// for campgrounds, filter out dispersed filters
				return this.filters.filter(filter => filter.category !== 'dispersed');
			case 'dispersed':
				// for dispersed, filter out campground filters
				return this.filters.filter(filter => filter.category !== 'campgrounds');
			default:
				return currentFilters;
		}
	}	
}

export default CampingActivityFilters;