import React from 'react';
import './ActivitiesList.scss';

const ActivitiesList = props => {
	return (
		<ul className='activities-list'>
			{Object.keys(props.activities).map(activityName => (
				<li key={activityName}
					className={`activity-${activityName}`}
					onClick={() => props.setActivity(props.activities[activityName])}>
					<div>
						<label dangerouslySetInnerHTML={{__html: props.activities[activityName].label}} />
					</div>
				</li>
			))}
		</ul>
	);
}

export default ActivitiesList;