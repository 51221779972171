import ActivityFilter from '../../activity-filter';

class CampgroundsEquipmentFilter extends ActivityFilter {

	/**
	 * Filter slug (query param in resulting url)
	 */
	
	slug = 'equipment';

	/**
	 * Filter category - optional property for grouping filters
	 */
	
	category = 'campgrounds';

	/**
	 * Filter label (e.g. 'What would you like to <b>hunt</b>?')
	 */
	
	label = 'What kind of <b>equipment</b> will you camp in?';

	/**
	 * Filter options may depend on previously selected filter values
	 * This method lets you return what you want based on previous selections
	 * or return different options based on screen width (e.g. paginated or not)
	 */
	
	getOptions = (data, page, currentFilters, screenWidth) => {
		return [
			{value: 'Tent', slug: 'tent'},
			{value: 'RV/Trailer', slug: 'rv'}
		];
	}
}

export default CampgroundsEquipmentFilter;