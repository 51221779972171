
const initialState = 0;

function roadsActivityIndexReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_ROADS_ACTIVITY_INDEX':
			if (isNaN(action.payload)) {
				return state;
			}
			
			return action.payload;
		default:
			return state;
	}
}

export default roadsActivityIndexReducer;