import ActivityFilters from '../';

class FishingActivityFilters extends ActivityFilters {

	/**
	 * Activity name
	 */
	
	name = 'fish';

	/**
	 * Activity label (e.g. 'Plan my <b>hunt</b>')
	 */
	
	label = 'Take me <b>fishing</b>';

	/**
	 * Activity panel router endpoint
	 */

	panelDestination = 'water';

	/**
	 * Activity filters
	 */
	
	filters = [];

	/**
	 * Whether or not to include a geocoder in this filter sequence
	 */
	
	hasGeocoder = true;

	/**
	 * Get label text for geocoder input
	 */
	
	getGeocoderLabel = (currentFilters) => {
		return 'Check <b>stream flows</b> and find <b>public land</b> near:';
	}

	/**
	 * Activity filters getter:
	 * Subsequent filters may depend on previously selected filter values
	 * This method lets you return what you want based on current selections
	 */
	
	getFilters = (currentFilters) => {
		return currentFilters;
	}	
}

export default FishingActivityFilters;