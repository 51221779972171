
const initialState = {
	saveToCollections: null,
	pendingCollections: [],
	placeObj: {},
	collections: []
};

function collectionsReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_SAVE_TO_COLLECTIONS':
			return {
				...state,
				saveToCollections: action.payload.bool,
				pendingCollections: action.payload.collectionIds,
				placeObj: action.payload.placeObj
			};
		case 'SET_COLLECTIONS':
			return {
				...state,
				collections: action.payload
			};
		case 'DELETE_COLLECTION':
			const collections = state.collections.filter(
				collection => collection.id !== action.payload
			);

			return {
				...state,
				collections,
			};
		default:
			return state;
	}
}

export default collectionsReducer;