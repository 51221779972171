import {months, parentControls, featureTypeProperties, stateAbbreviations} from '../explore/config/wildlife';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	selectedWildlifeState: null,
	selectedWildlifeSpecies: null,
	selectedMonth: null,
	featureTypes: {},
	featureTypeCache: populateFeatureTypeCache()
};

// populate cache with state names
function populateFeatureTypeCache() {
	return Object.keys(stateAbbreviations).reduce((cache, state) => {
		cache[state] = {};
		return cache;
	}, {});
}

function wildlifeReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_SELECTED_WILDLIFE_STATE':
			return {
				...state,
				selectedWildlifeState: action.payload
			};
		case 'SET_SELECTED_WILDLIFE_SPECIES':
			return {
				...state,
				selectedWildlifeSpecies: action.payload
			};
		case 'SET_SELECTED_MONTH':
			return {
				...state,
				selectedMonth: action.payload
			};
		case 'SET_FEATURE_TYPES_FROM_RESULTS': {
			const featureTypes = Object.keys(featureTypeProperties).reduce((result, type) => {

				// don't check month property for parent-level filters
				const isMonthly = (parentControls.indexOf(type) < 0);

				if (isMonthly) {

					result[type] = months.reduce((byMonths, month) => {
						byMonths[month] = action.payload[`is_${month.toLowerCase()}`].includes(type)
						return byMonths;
					}, {});

				} else {
					result[type] = action.payload[type];
				}
				return result;
			}, {});

			// add result to featureTypeCache
			const featureTypeCache = cloneDeep(state.featureTypeCache);
			const stateFeatureTypeCache = featureTypeCache[state.selectedWildlifeState.value];

			if (!stateFeatureTypeCache[state.selectedWildlifeSpecies.value]) {
				stateFeatureTypeCache[state.selectedWildlifeSpecies.value] = featureTypes;
			}

			return {
				...state,
				featureTypes,
				featureTypeCache
			};
		}
		case 'SET_FEATURE_TYPES':
			return {
				...state,
				featureTypes: action.payload
			};
		case 'SET_FEATURE_TYPE_CACHE':
			return {
				...state,
				featureTypeCache: action.payload
			};
		default:
			return state;
	}
}

export default wildlifeReducer;