import fetch from 'isomorphic-fetch';
import {apiBaseUrl} from '../config/config';

export default class ProfileApi {

	static getProfile(token) {
		return fetch(`${apiBaseUrl}/profile`, {
			headers: new Headers ({
				'token': token
			}),
			method: 'get'
		}).then(response => {
			if (response.status === 200) {
				return response.json()
			} else {
				throw new Error('Could not get profile');
			}
		});
	}
}