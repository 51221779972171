import React from 'react';
import './CompanySplash.scss';

const CompanySplash = props => (
	<section className='company-splash top-adjust' style={{backgroundImage: `url(${props.splash_image})`}}>
		<div className='company-splash-content'>
			<h1>{props.headline_1}</h1>
            <h2 dangerouslySetInnerHTML={{__html: props.headline_2}}></h2>
		</div>
	</section>
);

export default CompanySplash;