import React, {Component} from 'react';
import loader from './loader.svg';
import './Loader.scss';
import {CSSTransition} from 'react-transition-group';

class Loader extends Component {
	constructor(props) {
		super(props);

		this.state = {active: (props.loading && !props.timeout) || false};
		this.timeout = null;
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.loading) {
			if (!this.state.active) {
				this.set();	
			}
		} else {
			this.clear();
			this.setState({active: false});
		}
	}

	componentWillUnmount() {
		this.clear();
	}

	clear() {
		clearTimeout(this.timeout);
	}

	set() {
		this.clear();
		this.timeout = setTimeout(
			() => {
				this.setState({active: true});
			},
			(this.props.timeout || 0)
		);
	}

	render() {
		return (
			<CSSTransition
			    in={this.state.active}
			    classNames="loader-fade"
			    mountOnEnter={true}
			    unmountOnExit={true}
			    timeout={150}>
			  <div className="oa-loader">
					<img src={loader} alt="Loading" />
					{this.props.children}
				</div>
			</CSSTransition>
		);
	}
}

export default Loader;