
const initialState = {
	photos: []
};

function carouselPhotosReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_CAROUSEL_PHOTOS':
			return {photos: action.payload};
		default:
			return state;
	}
}

export default carouselPhotosReducer;