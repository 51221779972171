import ActivityFilter from '../../activity-filter';

class SpeciesFilter extends ActivityFilter {

	/**
	 * Filter slug (query param in resulting url)
	 */
	
	slug = 'species';

	/**
	 * Filter label (e.g. 'What would you like to <b>hunt</b>?')
	 */
	
	label = 'What would you like to <b>hunt</b>?';

	/**
	 * Number of filter options to show per page (on desktop)
	 * If null, don't paginate
	 */
	
	perPage = 7;

	/**
	 * Filter options may depend on previously selected filter values
	 * This method lets you return what you want based on previous selections
	 * or return different options based on screen width (e.g. paginated or not)
	 */
	
	getOptions = (data, page, currentFilters, screenWidth) => {
		// return unpaginated items for smaller screens where horiz. scroll is enabled
		if (screenWidth <= 768) {
			// return nothing for pages > 0
			if (page > 0) {
				return [];
			}
			
			return data.species;
		}

		return ActivityFilter.paginate(data.species, page, this.perPage)
	}

	/**
	 * Determine if a given option is currently selected
	 */

	isOptionSelected(option) {
		if (this.value === null || this.value === undefined) {
			return false;
		}

		return this.value.id === option.id;
	}

	/**
	 * Get the classname that should be applied to a given option in the dom
	 */

	getOptionClassName(option) {
		return `filter-option-${option.value.replace(/\s/g, '_').toLowerCase()}`;
	}
}

export default SpeciesFilter;