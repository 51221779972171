import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	pointCreationActive: false,
	pointEditStatus: false,
	pendingPoint: null,
	pendingMarker: null,
	points: [],
	geojson: null
};

function getGeojsonFromPoints(points) {
	const geojson = {
		"type": "FeatureCollection"
	};

	if(points) geojson.features = points.reduce((acc, point) => {
		const lng = point.lat_lng.coordinates[0];
		const lat = point.lat_lng.coordinates[1];

		acc.push({
			"type": "Feature",
			"geometry": {
				"type": "Point",
				"coordinates": [lng, lat]
			},
			"properties": {
				...point
			}
		});

		return acc;
	}, []);

	return geojson;
}

function pointsReducer(state = initialState, action) {
	let points;
	switch (action.type) {
		case 'DELETE_POINT':
			points = cloneDeep(state.points).filter(point => point.id !== action.payload);
			return {
				...state,
				points,
				geojson: getGeojsonFromPoints(points)
			};
		case 'ADD_POINT':
			points = [...cloneDeep(state.points), action.payload];
			return {
				...state,
				points,
				geojson: getGeojsonFromPoints(points)
			};
		case 'SET_POINTS':
			return {
				...state,
				points: action.payload,
				geojson: getGeojsonFromPoints(action.payload)
			};
		case 'SET_POINT_CREATION_ACTIVE':
			return {
				...state,
				pointCreationActive: action.payload
			};
		case 'SET_POINT_EDIT_STATUS':
			return {
				...state,
				pointEditStatus: action.payload
			};
		case 'SET_PENDING_POINT':
			return {
				...state,
				pendingPoint: action.payload
			};
		case 'SET_PENDING_MARKER':
			return {
				...state,
				pendingMarker: action.payload
			};
		default:
			return state;
	}
}

export default pointsReducer;