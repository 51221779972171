import React from 'react';
import './OutlyLogo.scss';
import {NavLink} from 'react-router-dom';

const LogoPathsDesktop = props => (
	<svg viewBox="0 0 151 54" className={`outly-logo outly-logo-${props.theme}-theme`}>
		<g className='outly-logo-mountains'>
			<path d="M16,26.7143389 L10.5390827,17.9676806 L0,34.850052 C1.49200061,37.8615318 3.48296003,40.5923071 5.85342069,42.9676806 L16,26.7143389 Z" fill="#70CF71"></path>
			<path d="M37,20.0739955 L29.1783117,6.9676806 L7,44.1337078 C10.2809042,47.1962749 14.21546,49.5484032 18.5633892,50.9676806 L37,20.0739955 Z" fill="#39B54A"></path>
			<path d="M59.7802363,21.3606269 C59.7802363,13.1381194 56.7028245,5.64501493 51.6519589,-1.42108547e-14 L20.5821808,52.1599701 C23.0873466,52.8136119 25.7141146,53.161791 28.4203613,53.161791 C45.7403399,53.161791 59.7802363,38.9243284 59.7802363,21.3606269" fill="#006838"></path>
		</g>
		<g transform="translate(74.000000, 17.000000)" className='outly-logo-text'>
			<path d="M10.8418271,13.7225388 C11.059355,13.0458578 11.1668969,12.0988299 11.1668969,9.50040714 C11.1668969,6.8751125 11.059355,5.95495649 10.8418271,5.27827541 C10.3269295,3.78973985 9.2441781,2.86958385 7.40293043,2.86958385 C5.58856823,2.86958385 4.50581683,3.78973985 3.99173397,5.27827541 C3.77420603,5.95495649 3.66584942,6.8751125 3.66584942,9.50040714 C3.66584942,12.0988299 3.77420603,13.0458578 3.99173397,13.7225388 C4.50581683,15.2110744 5.58856823,16.1312304 7.40293043,16.1312304 C9.2441781,16.1312304 10.3269295,15.2110744 10.8418271,13.7225388 M0.903977915,14.5612651 C0.525137133,13.4253203 0.416780522,12.3960485 0.416780522,9.50040714 C0.416780522,6.60476579 0.525137133,5.57549393 0.903977915,4.43954914 C1.85230194,1.51622166 4.23533266,0 7.40293043,0 C10.5982284,0 12.9812591,1.51622166 13.9287684,4.43954914 C14.3084239,5.57549393 14.4167805,6.60476579 14.4167805,9.50040714 C14.4167805,12.3960485 14.3084239,13.4253203 13.9287684,14.5612651 C12.9812591,17.4845926 10.5982284,19 7.40293043,19 C4.23533266,19 1.85230194,17.4845926 0.903977915,14.5612651"></path>
			<path d="M28.3549261,12.0710553 L28.3549261,0.275470219 C28.3549261,0.110849878 28.4587575,0 28.6141142,0 L31.1575924,0 C31.3129492,0 31.4167805,0.110849878 31.4167805,0.275470219 L31.4167805,12.1256531 C31.4167805,16.4694792 28.6398768,19 24.9035088,19 C21.1929035,19 18.4167805,16.4694792 18.4167805,12.1256531 L18.4167805,0.275470219 C18.4167805,0.110849878 18.5206119,0 18.6759686,0 L21.2186662,0 C21.3748036,0 21.4778543,0.110849878 21.4778543,0.275470219 L21.4778543,12.0710553 C21.4778543,14.6561738 22.9057308,16.0856409 24.9035088,16.0856409 C26.9278302,16.0856409 28.3549261,14.6561738 28.3549261,12.0710553"></path>
			<path d="M39.6575939,19 C39.5037707,19 39.4009641,18.8873601 39.4009641,18.7200813 L39.4009641,3.12954032 C39.4009641,3.017741 39.3499474,2.96226164 39.2471408,2.96226164 L34.6734103,2.96226164 C34.519587,2.96226164 34.4167805,2.85046232 34.4167805,2.68318364 L34.4167805,0.279918594 C34.4167805,0.112639915 34.519587,0 34.6734103,0 L47.1593777,0 C47.313974,0 47.4167805,0.112639915 47.4167805,0.279918594 L47.4167805,2.68318364 C47.4167805,2.85046232 47.313974,2.96226164 47.1593777,2.96226164 L42.5864202,2.96226164 C42.4836137,2.96226164 42.4325969,3.017741 42.4325969,3.12954032 L42.4325969,18.7200813 C42.4325969,18.8873601 42.3297904,19 42.1759671,19 L39.6575939,19 Z"></path>
			<path d="M51.4167805,0.279918594 C51.4167805,0.112639915 51.524451,0 51.685552,0 L54.3230748,0 C54.4833662,0 54.5910367,0.112639915 54.5910367,0.279918594 L54.5910367,15.8704597 C54.5910367,15.9822589 54.6452768,16.0377383 54.7529473,16.0377383 L63.148009,16.0377383 C63.30911,16.0377383 63.4167805,16.1495377 63.4167805,16.3168163 L63.4167805,18.7200813 C63.4167805,18.8873601 63.30911,19 63.148009,19 L51.685552,19 C51.524451,19 51.4167805,18.8873601 51.4167805,18.7200813 L51.4167805,0.279918594 Z"></path>
			<path d="M68.350227,19 C68.1966976,19 68.0935693,18.8873601 68.0935693,18.7200814 L68.0935693,11.2328895 L63.0154677,0.279918595 C62.9642912,0.140379601 63.0418313,0 63.2224998,0 L65.8255212,0 C65.9790506,0 66.1085426,0.0840596434 66.1853073,0.279918595 L69.6133554,7.65615184 L69.6908955,7.65615184 L73.1453073,0.279918595 C73.2212966,0.0840596434 73.3252003,0 73.5050934,0 L76.0049864,0 C76.2120185,0 76.263195,0.140379601 76.2120185,0.279918595 L71.1339169,11.2328895 L71.1339169,18.7200814 C71.1339169,18.8873601 71.0307885,19 70.8764837,19 L68.350227,19 Z"></path>
		</g>
	</svg>
);

const LogoPathsMobile = props => (
	<svg viewBox="0 0 60 54" className={`outly-logo-mobile outly-logo-${props.theme}-theme`}>
		<g className='outly-logo-mountains'>
	    <path d="M16,26.7143389 L10.5390827,17.9676806 L0,34.850052 C1.49200061,37.8615318 3.48296003,40.5923071 5.85342069,42.9676806 L16,26.7143389 Z" fill="#70CF71"></path>
	    <path d="M37,20.0739955 L29.1783117,6.9676806 L7,44.1337078 C10.2809042,47.1962749 14.21546,49.5484032 18.5633892,50.9676806 L37,20.0739955 Z" fill="#39B54A"></path>
	    <path d="M59.7802363,21.3606269 C59.7802363,13.1381194 56.7028245,5.64501493 51.6519589,-1.42108547e-14 L20.5821808,52.1599701 C23.0873466,52.8136119 25.7141146,53.161791 28.4203613,53.161791 C45.7403399,53.161791 59.7802363,38.9243284 59.7802363,21.3606269" fill="#006838"></path>
		</g>
	</svg>
);

const OutlyLogo = props => {
	let {theme = 'dark'} = props;

	return (
		<NavLink to={props.to} aria-label="Outly" className='outly-logo-container'>
			<LogoPathsDesktop theme='dark' />
			<LogoPathsMobile theme={theme} />
		</NavLink>
	);
};

export default OutlyLogo;