import cloneDeep from 'lodash/cloneDeep';

const initialState = {
  isDrawingMode: false,
  glDrawMode: null,
  hideDrawingInstructions: {
    draw_line_string: false,
    direct_select: false
  },
  routes: [],
  geojson: null,
  startMarker: null,
  endMarker: null
};

function getGeojsonFromRoutes(routes) {
  const geojson = {
    "type": "FeatureCollection"
  };

  if (routes) geojson.features = routes.reduce((acc, route) => {
    const coordinates = route.route.coordinates;

    acc.push({
      "type": "Feature",
      "geometry": {
        "type": "LineString",
        "coordinates": coordinates
      },
      "properties": {
        ...route
      }
    });

    return acc;
  }, []);

  return geojson;
}

function routesReducer(state = initialState, action) {
  let routes;
  switch(action.type) {
    case 'DELETE_ROUTE':
      routes = cloneDeep(state.routes)
        .filter(route => route.id !== action.payload);

      if (state.startMarker) {
        state.startMarker.remove();
      }

      if (state.endMarker) {
        state.endMarker.remove();
      }

      return {
        ...state,
        routes,
        geojson: getGeojsonFromRoutes(routes),
        startMarker: null,
        endMarker: null
      };
    case 'SET_IS_DRAWING_MODE':
      return {
        ...state,
        isDrawingMode: action.payload
      };
    case 'SET_GL_DRAW_MODE':
      return {
        ...state,
        glDrawMode: action.payload
      }
    case 'HIDE_DRAWING_INSTRUCTIONS':
      return {
        ...state,
        hideDrawingInstructions: {
          ...state.hideDrawingInstructions,
          [action.payload]: true
        }
      };
    case 'SET_ROUTES':
      return {
        ...state,
        routes: action.payload,
        geojson: getGeojsonFromRoutes(action.payload)
      };
    case 'SET_START_MARKER':
      return {
        ...state,
        startMarker: action.payload
      };
    case 'SET_END_MARKER':
      return {
        ...state,
        endMarker: action.payload
      };
    case 'CLEAR_MARKERS':
      if (state.startMarker) {
        state.startMarker.remove();
      }

      if (state.endMarker) {
        state.endMarker.remove();
      }

      return {
        ...state,
        startMarker: null,
        endMarker: null
      };
    case 'CLEAR_ALL_FEATURES':
    case 'CLEAR_ROUTE_SELECTION':
      routes = state.routes.map(route => {
        route.selected = 'false';
        return route;
      });

      if (state.startMarker && state.endMarker) {
        state.startMarker.remove();
        state.endMarker.remove();
      }

      return {
        ...state,
        routes: routes,
        geojson: getGeojsonFromRoutes(routes),
        startMarker: null,
        endMarker: null
      };
    case 'SET_ROUTE_SELECTED':
      const routeId = action.payload.properties.id;

      routes = state.routes.map(route => {
        if (route.id === routeId) {
          route.selected = 'true';
          route.visible = true;
        } else {
          route.selected = 'false';
        }

        return route;
      });

      return {
        ...state,
        routes: routes,
        geojson: getGeojsonFromRoutes(routes)
      };

    default:
      return state;
  }
}

export default routesReducer;