import ActivityFilters from '../';
import ActivityFilter from '../activity-filter';
import SpeciesFilter from './filters/species';
import StateFilter from './filters/state';
import {apiBaseUrl} from '../../config/config';
import fetch from 'isomorphic-fetch';
import store from '../../store';

class HuntingActivityFilters extends ActivityFilters {

	/**
	 * Activity name
	 */
	
	name = 'hunt';

	/**
	 * Activity label (e.g. 'Plan my <b>hunt</b>')
	 */
	
	label = 'Plan my <b>hunt</b>';

	/**
	 * Activity panel router endpoint
	 */

	panelDestination = 'hunting';

	/**
	 * Activity filters
	 */
	
	filters = [
		new SpeciesFilter(),
		new StateFilter()
	];

	/**
	 * Activity filters getter:
	 * Subsequent filters may depend on previously selected filter values
	 * This method lets you return what you want based on current selections
	 */
	
	getFilters = (currentFilters) => {
		return currentFilters;
	}

	/**
	 * Get url query params for filter values
	 */

	getFilterParams = async () => {
		let params = '';

		const stateFilter = ActivityFilter.getFilterBySlug(this.filters, 'state');
		const speciesFilter = ActivityFilter.getFilterBySlug(this.filters, 'species');

		let speciesID = speciesFilter.value
			? speciesFilter.value.id
			: 1; // default to Elk

		let stateID = stateFilter.value
			? stateFilter.value.id
			: 1; // default to Colorado

		// if species is selected but state isn't, find the first state
		// that has selected species
		if (speciesFilter.value && !stateFilter.value) {
			const state = store.getState().staticData.states.find(state => {
				return state.species.find(species => species.id === speciesFilter.value.id);
			});

			stateID = state.id;
		}

		params += `&stateID=${stateID}`;
		params += `&speciesID=${speciesID}`;

		const unitLayer = await getUnitLayer(
			stateID,
			speciesID
		);

		if (unitLayer) {
			params += `&stateSpeciesID=${unitLayer.id_state_species}`;
			params += `&unitTypeID=${unitLayer.id}`;
			params += `&unitTypeValue=${unitLayer.value ? unitLayer.value.replace(/\s/g, '_') : null}`;
			params += `&unitTypeLayer=${unitLayer.layer_id}`;
			params += `&harvest=${unitLayer.harvest_exists}`;
			params += `&draw=${unitLayer.draw_exists}`;
			params += `&totalHarvest=${unitLayer.total_harvest_exists}`;
			params += `&trophy=${unitLayer.percent_trophy_exists}`;
			params += `&males=${unitLayer.num_males_exists}`;
		}

		return params;
	}		
}



/**
 * Determine which unit layer to enable
 */

async function getUnitLayer(stateID, speciesID) {
	// Get hunt unit layer type for this state/species
	const unitLayers = await fetch(
		`${apiBaseUrl}/unit-layers/${stateID}/${speciesID}`,
	).then(response => response.json());

	// If only one result, return it
	if (unitLayers.length === 1) {
		return unitLayers[0];
	}

	// Otherwise return the one with default_third = true
	return (unitLayers.find(type => type.default_third) || null);
}


export default HuntingActivityFilters;