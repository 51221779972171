const initialState = {
	// content: {
	// 	icon: ...,
	// 	title: ...,
	// 	description: ...,
	// 	ctaText: ...,
	// 	label: ...
	// }
	content: null,
	hasSeenInitialAnnoyingModal: false
};

function infoModalReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_INFO_MODAL_CONTENT': {
			return {
				...state,
				content: action.payload
			};
		}
		case 'SET_INITIAL_ANNOYING_MODAL_STATUS': {
			return {
				...state,
				hasSeenInitialAnnoyingModal: action.status
			};
		}
		default: {
			return state;
		}
	}
}

export default infoModalReducer;