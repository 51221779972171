import ActivityFilter from '../../activity-filter';

class CampgroundsWaterFilter extends ActivityFilter {

	/**
	 * Filter slug (query param in resulting url)
	 */
	
	slug = 'water';

	/**
	 * Filter category - optional property for grouping filters
	 */
	
	category = 'campgrounds';

	/**
	 * Filter label (e.g. 'What would you like to <b>hunt</b>?')
	 */
	
	label = 'Do you only want to camp near <b>creeks</b>, <b>rivers</b>, or <b>lakes</b>?';

	/**
	 * Filter options may depend on previously selected filter values
	 * This method lets you return what you want based on previous selections
	 * or return different options based on screen width (e.g. paginated or not)
	 */
	
	getOptions = (data, page, currentFilters, screenWidth) => {
		return [
			{value: 'Yes, only near water', slug: 'near-water'},
			{value: "No, I'll camp anywhere", slug: 'water-optional'}
		];
	}
}

export default CampgroundsWaterFilter;