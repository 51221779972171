import staticDataApi from '../api/staticDataApi';

export function getStaticData() {
	return function(dispatch) {
		return staticDataApi.getStaticData().then(data => {
			dispatch(loadStaticDataSuccess(data));
		}).catch(error => {
			throw(error);
		});
	}
}

export function loadStaticDataSuccess(data) {
	return {type: 'LOAD_STATIC_DATA_SUCCESS', data};
}
