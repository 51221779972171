import React from 'react';
import {Waypoint} from 'react-waypoint';

const ScrollTrigger = props => {
	const scrollContainer = window.innerWidth > 768
		? 'window'
		: document.getElementById('root');

	return (
		<Waypoint onPositionChange={props.onChange} scrollableAncestor={scrollContainer}>
			<div className={props.className} />
		</Waypoint>
	);
};

export default ScrollTrigger;