import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
// Import Global Styles
import './styles/main.scss';
import OALanding from './pages/OALanding/OALanding';
import Landing from './pages/Landing/Landing';
import HarvestTool from './pages/HarvestTool/HarvestTool';
import Loader from './components/Loader/Loader';
import { getProfile } from './actions/profile';
import { isProTrialPeriod } from './config/config';
// Routing components
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import NestedBrowserRouter from './utils/nestedBrowserRouter';
// Lazy load routed components
const Activities = lazy(() => import('./pages/Interior/Activities/Activities'));
// const OutlyPro = lazy(() => import('./pages/Interior/OutlyPro/OutlyPro'));
const AboutUs = lazy(() => import('./pages/Interior/AboutUs/AboutUs'));
const Contact = lazy(() => import('./pages/Interior/Contact/Contact'));
const OAContact = lazy(() => import('./pages/Interior/OAContact/OAContact'));
const CreateProfile = lazy(() => import('./pages/CreateProfile/CreateProfile'));
const User = lazy(() => import('./pages/User/User'));
const Share = lazy(() => import('./pages/Share/Share'));
const Terms = lazy(() => import('./pages/Terms/Terms'));
const Journal = lazy(() => import('./pages/Journal/Journal'));
const Article = lazy(() => import('./pages/Journal/Article/Article'));
const Auth = lazy(() => import('./pages/Auth/Auth'));
const Outly = lazy(() => import('./explore/App'));

/**
 * This is a component that is simply a redirect helper to push /outly before the current path
 * it is intended as a stop gap component until we go through and clean up all routing that's not 
 * properly pointing to /outly/* (an example being when you click on "Explore Map" and a redirect happens
 * to /explore/my-places assuming you're signed in)
 * @param {*} props 
 */
const OutlyRedirectHelper = (props) => {
  const {history} = props;
  history.push(`/outly${history.location.pathname}`);
  return null;
}

class App extends React.Component {
  componentDidMount() {
    this.token = localStorage.getItem('jwt');

    if (this.token) {
      this.props.dispatch(getProfile(this.token));
    }
  }

  renderHarvestToolRoute() {
    if (process.env.NODE_ENV === 'production') {
      return null;
    }

    return (
      <Route exact path="/harvest" component={HarvestTool} />
    );
  }

  renderRoutesThatHaveToBeSupportedWithAndWithoutBasename(asRedirects = false) {
    const authPageSlugs = [
      'log-in',
      'create-account',
      'forgot-password',
      'verification',
      'reset-password',
      'activate'
    ];
    if (!isProTrialPeriod) {
      authPageSlugs.push('create-pro-account', 'go-pro');
    }
    // FIXME: All paths below this should eventually be properly fixed in the application as there are redirects that point to these endpoints without `basename`
    // For example, on clicking the "Explore Map" link, there is a redirect to '/my-places' without consideration for the base route
    const pathComponentPairs = [
      ["/explore",  Outly],
      ["/:activity(hunting|fishing|camping|trails-and-roads)",  Activities],
      [`/:path(${authPageSlugs.join('|')})`,  Auth],
      ["/user/profile",  User],
      ["/user/account", User]
    ]
    return pathComponentPairs.map((pair, i) => asRedirects ? <Route key={i} path={pair[0]} component={OutlyRedirectHelper}/> : <Route key={i} path={pair[0]} component={pair[1]}/>)
  }

  render() {
    const basename = '/outly';

    return (
      <React.Fragment>
        <BrowserRouter>
          <Suspense fallback={<Loader loading={true} />}>
            <Switch>
              <Route exact path="/company" component={OALanding} />
              <Route exact path="/company-contact" component={OAContact} />
              <Route exact path="/terms-of-service" component={Terms} />
              <Route exact path="/privacy-policy" component={Terms} />
              <Route exact path="/cookie-policy" component={Terms} />
              <Route
                path={basename}
                render={() => (
                  <NestedBrowserRouter basename={basename.substr(1)}>
                    <Switch>
                      <Route exact path="/" component={Landing} />
                      {this.renderRoutesThatHaveToBeSupportedWithAndWithoutBasename()}
                      {/* <Route exact path="/outly-pro" component={OutlyPro} /> */}
                      <Route exact path="/about-us" component={AboutUs} />
                      <Route exact path="/contact" component={Contact} />
                      <Route exact path="/create-profile" component={CreateProfile} />
                      <Route exact path="/share/:string" component={Share} />
                      <Route exact path="/journal" component={Journal} />
                      <Route exact path="/journal/:article" component={Article} />
                      {this.renderHarvestToolRoute()}
                      {/* If we fall through in outly, redirect to outly */}
                      <Redirect to={"/"}/>
                    </Switch>
                  </NestedBrowserRouter>
                )} />
              {this.renderRoutesThatHaveToBeSupportedWithAndWithoutBasename(true)}
              <Redirect to={`${basename}`} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}


export default connect()(App);