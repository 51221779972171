
const initialState = {
	userLoaded: false,
	token: null,
};

function authReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_USER_TOKEN':
			return {
				...state,
				token: action.payload
			};
		case 'SET_USER_LOADED':
			return {
				...state,
				userLoaded: action.payload
			};
		default:
			return state;
	}
}

export default authReducer;