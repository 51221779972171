import cloneDeep from 'lodash/cloneDeep';

const initialState = {
	mapCreationStatus: false,
	maps: []
};

function mapsReducer(state = initialState, action) {
	switch (action.type) {
		case 'DELETE_MAP': {
			const maps = cloneDeep(state.maps)
				.filter(map => map.id !== action.payload);
				
			return {
				...state,
				maps,
            };
        }
		case 'SET_MAP_CREATION_STATUS':
			return {
				...state,
				mapCreationStatus: action.payload
			};
		case 'SET_MAPS':
			return {
				...state,
				maps: action.payload
			};
		default:
			return state;
	}
}

export default mapsReducer;