
export function applyHuntSettings(payload) {
	return {type: 'APPLY_HUNT_SETTINGS', payload};
}

export function setSelectedHuntState(payload) {
	return {type: 'SET_SELECTED_HUNT_STATE', payload};
}

export function setSelectedHuntSpecies(payload) {
	return {type: 'SET_SELECTED_HUNT_SPECIES', payload};
}

export function setSelectedHuntType(payload) {
	return {type: 'SET_SELECTED_HUNT_TYPE', payload};
}

export function setHuntParams(payload) {
	return {type: 'SET_HUNT_PARAMS', payload};
}

export function setFillData(payload) {
	return {type: 'SET_FILL_DATA', payload};
}

export function setHuntAnalytic(payload) {
	return {type: 'SET_HUNT_ANALYTIC', payload};
}

export function setHuntAnalytics(payload) {
	return {type: 'SET_HUNT_ANALYTICS', payload};
}