import ActivityFilter from '../../activity-filter';
import dispersedModalImage from '../../../assets/images/dispersed.jpg';
import campIcon from '../../../assets/icons/camp.svg';
import infoIcon from '../../../assets/icons/info.svg';

class CampingTypeFilter extends ActivityFilter {

	/**
	 * Filter slug (query param in resulting url)
	 */
	
	slug = 'campingType';

	/**
	 * Filter label (e.g. 'What would you like to <b>hunt</b>?')
	 */
	
	label = 'What type of <b>campsite</b>?';

	/**
	 * Optional link/content for the InfoModal component
	 */

	infoModalContent = {
		icon: infoIcon,
		title: 'Dispersed Camping',
		description: 'Dispersed camping, also known as “primitive camping” or “boondocking” is camping for free on public land! These spots are accessible by car or truck and are located outside of designated campgrounds. They offer no amenities other than a fire pit, but they typically provide privacy and a unique experience not found in most campgrounds.',
		ctaText: 'Got It',
		label: 'What is dispersed camping?'
	};

	/**
	 * Filter options may depend on previously selected filter values
	 * This method lets you return what you want based on previous selections
	 * or return different options based on screen width (e.g. paginated or not)
	 */
	
	getOptions = (data, page, currentFilters, screenWidth) => {
		return [
			{value: 'Campgrounds', slug: 'campgrounds'},
			{value: 'Cabin/Yurt/Lookout', slug: 'cabin'},
			{
				value: 'Dispersed Camping',
				slug: 'dispersed',
				pro: true,
				proModalContent: {
					image: dispersedModalImage,
					icon: campIcon,
					title: 'Dispersed Camping',
					description: 'Join the Outly Pro community to access 1100+ dispersed campsites on public land across the U.S. Filter and view details for each site such as 2WD/4WD Access, RV/Trailer Friendly, Near Water, Shade, Usage.',
					ctaSlug: 'outly-pro',
					ctaText: 'Get Outly Pro'
				}
			}
		];
	}
}

export default CampingTypeFilter;