
const initialState = {
	sharePlaces: [], 
	placePendingDelete: null,
	activeTabIndex: 0,
	layerVisible: true,
	showCreatePointMobileDialog: false,
	showDeleteDialog: false,
	prevPlaces: null,
	activeFeatureMenu: {feature: null, featureId: null},
	tabs: [
		{title: 'Trips', slug: 'collections', method: 'renderCollectionsList'},
		{title: 'Points', slug: 'points', method: 'renderPointsList'},
		{title: 'Routes', slug: 'routes', method: 'renderRoutesList'},
		{title: 'Maps', slug: 'maps', method: 'renderMapsList'},
		{title: 'Submissions', slug: 'submissions', method: 'renderSubmissionsList'}
	]
};

function myPlacesReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_ACTIVE_TAB_INDEX':
			return {
				...state,
				activeTabIndex: action.payload
			};
		case 'SET_PLACE_PENDING_DELETE':
			return {
				...state,
				placePendingDelete: action.payload
			};
		case 'SET_SHARE_PLACES':
			return {
				...state,
				sharePlaces: action.payload
			};
		case 'SET_MY_PLACES_LAYER_VISIBLE':
			return {
				...state,
				layerVisible: action.payload
			};
		case 'SET_SHOW_CREATE_POINT_MOBILE_DIALOG':
			return {
				...state,
				showCreatePointMobileDialog: action.payload
			};
		case 'SET_SHOW_DELETE_DIALOG':
			return {
				...state,
				showDeleteDialog: action.payload
			};
		case 'SET_PREV_PLACES':
			return {
				...state,
				prevPlaces: action.payload
			};
		case 'SET_ACTIVE_FEATURE_MENU':
			return {
				...state,
				activeFeatureMenu: action.payload
			}
		default:
			return state;
	}
}

export default myPlacesReducer;