
/**
 * Mapbox GL JS Access Token
 */

export const mapboxToken =
  "pk.eyJ1IjoiYnJldHRvYSIsImEiOiJjamh1djV2N2YwZW8yM3dwaThjbjdxbXBjIn0.5ZklKePkkraCkPJGhYc-fw";

/**
 * Butter CMS Token
 */

export const butterToken = "71bf0015716ca28b85d47c2702cf9fd67eae0bb5";


/**
 * Mapbox styles
 */

export const styles = ['map', 'sat'];

/**
 * Load timeout in millis
 */

export const loadTimeout = 30000;

/**
 * Default modals seen
 */

export const modalsSeen = {
  camping: false,
  tnr: false,
  fishing: false,
  hunting: false,
  explore: false
};

/**
 * Panel width in pixels (desktop)
 */

export const panelWidth = 386;

/**
 * Color of selected features
 */

export const selectedColor = "#00c853";


/**
 * Layers with pointer cursor
 */

export const pointerLayers = [
  'roads_and_trails_solid',
  'roads_and_trails_dashed',
  'roads_and_trails_highway',
  'roads_and_trails_highlight',
  "water_stream_flow_normal",
  "water_stream_flow_low",
  "water_stream_flow_high",
  "water_stream_flow_other",
  'water_swimming_hole_icons',
  "hunting_hunt_units_label",
  "hunting_analytics_label",
  'camping_icons',
  "camping-click-target",
  "my_places_points",
  "my_places_routes_stroke",
  "my_places_routes_dash",
  "my_places_submissions_swimming-hole",
  "my_places_submissions_dispersed-site",
  "my_places_submissions_campground"
];

/**
 * Stream flow colors
 */

export const streamFlowColors = {
  darkRed: "#a50f15",
  red: "#fb6a4a",
  lightRed: "#fee5d9",
  gray: "#9b9b9b"
};

/**
 * Feature type labels
 */

export const featureTypePluralLabels = {
  hunt_units_fill: "Units",

  "water_stream_flow_low": "Gauges",
  "water_stream_flow_normal": "Gauges",
  "water_stream_flow_high": "Gauges",
  "water_stream_flow_other": "Gauges",

  "my_places_points": "Points",
  "my_places_submissions_swimming-hole": "Submissions",
  "my_places_submissions_dispersed-site": "Submissions",
  "my_places_submissions_campground": "Submissions"
};
