import profileApi from '../api/profileApi';

export function getProfile(token) { 
  return function(dispatch) {
    return profileApi.getProfile(token).then(profile => {
      dispatch(loadProfileSuccess(profile));
    }).catch(error => {
      dispatch(loadProfileFail());
      localStorage.removeItem('jwt');
    });
  };
}

export function loadProfileSuccess(payload) {
  return {type: 'LOAD_PROFILE_SUCCESS', payload};
}

export function loadProfileFail() {
  return {type: 'LOAD_PROFILE_FAIL'};
}

export function loadUserSuccess(user) {
  return {type: 'LOAD_USER_SUCCESS', user};
}