import React from 'react';
import './Header.scss'
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import OutlyLogo from '../OutlyLogo/OutlyLogo';
import OALogo from '../OALogo/OALogo';
import MobileNavTarget from '../MobileNavTarget/MobileNavTarget';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileNavActive: false
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.location.pathname !== this.props.location.pathname) {
			this.setState({mobileNavActive: false});
		}
	}

	isLoggedIn() {
		return this.props.profile && Object.keys(this.props.profile).length;
	}

	handleMobileNavTargetClick = () => {
		this.setState({mobileNavActive: !this.state.mobileNavActive});
	}

	getHeaderClassName() {
        let className = 'oa-header oa-header-dark-theme'

		// mobile nav active state
		if (this.state.mobileNavActive) {
			className += ' oa-menu-active';
		}

		// other arbitraty classes
		if (this.props.className) {
			className += ` ${this.props.className}`;
		}

		return className;
	}

	trackExploreMap = e => {
		e.preventDefault();

		this.props.history.push(this.props.exploreDestination);
	}

	// doGoOutlyPro = () => {
	// 	this.props.history.push('/outly-pro');
	// }

	renderOutlyProLink() {
		if (this.isLoggedIn() && this.props.profile.has_subscription) {
			/* this is only for hiding, not for features */
			document.body.classList.add('pro-user');
			return null;
		}

		const link = (
			<NavLink to="/outly-pro" activeClassName="active">Outly Pro</NavLink>
		);

		const button = (
			<NavLink to="/outly-pro" activeClassName="active">
				<button className="oa-button">Outly Pro</button>
			</NavLink>
		);

		return this.isLoggedIn()
			? button
			: link;
	}

	renderProfileLink() {
		if (!this.isLoggedIn()) {
			return null;
		}

		return (
			<NavLink to="/user/profile" activeClassName="active">My Profile</NavLink>
		);
	}

	renderAuthLinks() {
		if (this.isLoggedIn()) {
			return null;
		}

		return (
			<React.Fragment>
				<NavLink to="/create-account" activeClassName="active"><button className="oa-button">Sign Up</button></NavLink>
				<NavLink to="/log-in" activeClassName="active">Log In</NavLink>
			</React.Fragment>
		);
	}

	renderMobileCta() {
		if (this.isLoggedIn()) {
			return null;
		}

		return (
			<NavLink to="/log-in" className='oa-button mobile-cta'>Log In</NavLink>
		);
	}

	renderOutlyHeader() {
		return (
			<React.Fragment>
				<MobileNavTarget onClick={this.handleMobileNavTargetClick} isActive={this.state.mobileNavActive} />
				<OutlyLogo to='/' theme={this.props.theme} />
				<nav>
					<a href={`/outly${this.props.exploreDestination}`} onClick={this.trackExploreMap} className="explore-map-btn">Explore Map</a>
					<div className="header-dropdown">
						<button className="dropdown-btn">Activities</button>
						<div className="dropdown-content">
							<NavLink to="/hunting" activeClassName="active">Hunt</NavLink>
							<NavLink to="/fishing" activeClassName="active">Fish</NavLink>
							<NavLink to="/camping" activeClassName="active">Camp</NavLink>
							<NavLink to="/trails-and-roads" activeClassName="active">Ride</NavLink>
						</div>
					</div>
					<NavLink className="mobile-nav" to="/hunting" activeClassName="active">Hunt</NavLink>
					<NavLink className="mobile-nav" to="/fishing" activeClassName="active">Fish</NavLink>
					<NavLink className="mobile-nav" to="/camping" activeClassName="active">Camp</NavLink>
					<NavLink className="mobile-nav" to="/trails-and-roads" activeClassName="active">Ride</NavLink>
					<NavLink to="/journal" activeClassName="active">Journal</NavLink>
					{/* {this.renderOutlyProLink()} */}
					{this.renderProfileLink()}
					{this.renderAuthLinks()}
				</nav>
				{this.renderMobileCta()}
			</React.Fragment>
		);
	}

	renderOAHeader(){
		return (
			<React.Fragment>
				<MobileNavTarget onClick={this.handleMobileNavTargetClick} isActive={this.state.mobileNavActive} />
				<OALogo to='/company' theme={this.props.theme} />
                <nav>
                    <div className='header-dropdown'>
                        <button className='dropdown-btn'>Commercial</button>
                        <div className='dropdown-content'>
                            <NavLink to='/outly' activeClassName='active'>Outly</NavLink>
                        </div>
                    </div>
                    <div className='header-dropdown'>
                        <button className='dropdown-btn'>Government</button>
                        <div className='dropdown-content'>
                            <NavLink to='/company-contact' activeClassName='active'>Contact Us</NavLink>
                        </div>
                    </div>
					<NavLink className="mobile-nav" to="/outly" activeClassName="active">Commercial > Outly</NavLink>
					<NavLink className="mobile-nav" to="/company-contact" activeClassName="active">Government > Contact Us</NavLink>
                </nav>
			</React.Fragment>
		);
	}

	render() {
		return (
			<header className={this.getHeaderClassName()}>
				{(this.props.isOA) ? this.renderOAHeader() : this.renderOutlyHeader()}
			</header>
		);
	}
}

const mapStateToProps = state => ({
	exploreDestination: state.profile.exploreDestination,
	profile: state.profile.profile
});

export default connect(mapStateToProps, null, null, {pure: false})(withRouter(Header));