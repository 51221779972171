import React from 'react';
import './Geocoder.scss';
import fetch from 'isomorphic-fetch';
import {mapboxToken} from '../../config/config';

class Geocoder extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			query: '',
			results: [],
			cursor: -1
		};

		// debounce timer
		this.timeout = null;

		// container node ref
		this.containerNode = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('click', this.handleClickOutside);
	}
	
	componentWillUnmount() {
		document.removeEventListener('click', this.handleClickOutside);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location && !this.props.location) {
			this.setState({query: ''});
		}
	}

	handleClickOutside = e => {
		if (this.containerNode.current && !this.containerNode.current.contains(e.target)) {
			this.setState({results: []});
		}
	}

	handleKeyDown = e => {
		switch(e.key) {
			case 'ArrowDown':
				if (this.state.cursor < (this.state.results.length - 1)) {
					this.setState({cursor: this.state.cursor + 1});
				}
				break;
			case 'ArrowUp':
				if (this.state.cursor > 0) {
					this.setState({cursor: this.state.cursor - 1});
				}
				break;
			case 'Escape':
				this.handleClear();
				break;
			case 'Enter':
				if (this.state.cursor >=0) {
					this.handleResultClick(this.state.results[this.state.cursor]);
				}
				break;
			default:
		}
	}

	/**
	 * Perform geocoder search
	 */

	submitSearch = async () => {
		const {query} = this.state;

		if (query.length < 2) return;

		try {
			const results = await this.doGetResults(this.state.query);
			this.setState({
				results,
				cursor: -1
			});

		} catch (error) {
			this.setState({results: []});
		}
	}


	/**
	 * Query mapbox api
	 */

	doGetResults(query) {
		return fetch(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=US&access_token=${mapboxToken}`,
			{method: 'get'}
		).then(
			response => response.json()
		).then(
			data => data.features
		);
	}


	/**
	 * Handle result click
	 */

	handleResultClick = result => {
		this.setState({
			query: result.place_name,
			results: [],
			cursor: -1
		}, () => {
			this.props.setLocation(result.center);
		});
	}



	/**
	 * Handle input value change
	 */

	handleChange = e => {
		this.setState(
			{query: e.target.value},
			() => {
				// clear any current timer
				clearTimeout(this.timeout);
				this.timeout = setTimeout(this.submitSearch, 200);
			}
		);
	}


	/**
	 * Handle input focus
	 */

	handleFocus = e => {
		if (this.state.query.length && !this.state.results.length) {
			// clear any current timer
			clearTimeout(this.timeout);
			this.timeout = setTimeout(this.submitSearch, 200);
		}
	}


	/**
	 * Handle clear button click
	 */
	
	handleClear = e => {
		this.setState({
			query: '',
			results: []
		}, () => {
			this.props.setLocation(null);
		});
	}


	/**
	 * Render clear button
	 */

	renderClearButton() {
		if (!this.state.query) {
			return null;
		}

		return (
			<svg viewBox="0 0 17 17" className='oa-geocoder-clear' onClick={this.handleClear}>
		    <g transform="translate(1.000000, 1.000000)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
	        <circle cx="7.5" cy="7.5" r="7.5"></circle>
	        <path d="M9.75,5.25 L5.25,9.75"></path>
	        <path d="M5.25,5.25 L9.75,9.75"></path>
		    </g>
			</svg>
		);
	}

	/**
	 * Render query results
	 */

	renderResults() {
		const {results} = this.state;
		if (!results.length) return null;

		return (
			<React.Fragment>
				{results.map((item, i) => (
					<li key={i}
						className={this.state.cursor === i ? 'active' : ''}
						onClick={() => this.handleResultClick(item)}>
						{item.place_name}
					</li>
				))}
			</React.Fragment>
		);
	}


	render() {
		let className = 'oa-geocoder';

		if (this.state.results.length) {
			className += ' oa-geocoder-active';
		}
		
		return (
			<div className={className} ref={this.containerNode}>
				<svg viewBox="0 0 14 14" className='search-icon'>
			    <g transform="translate(1.000000, 1.000000)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
		        <circle cx="4.44444444" cy="4.44444444" r="4.44444444"></circle>
		        <path d="M11.4185549,11.4185549 L7.58333333,7.58333333"></path>
			    </g>
				</svg>
				<input type="text"
					placeholder='Search for a city, national park or monument'
					value={this.state.query}
					onChange={this.handleChange}
					onFocus={this.handleFocus}
					onKeyDown={this.handleKeyDown}
					spellCheck={false} />
				{this.renderClearButton()}
				<ul className="oa-geocoder-results">
					{this.renderResults()}
				</ul>
			</div>
		);
	}
}

export default Geocoder;