import ActivityFilter from '../../activity-filter';

class DispersedVehicleType extends ActivityFilter {

	/**
	 * Filter slug (query param in resulting url)
	 */
	
	slug = 'vehicle-type';

	/**
	 * Filter category - optional property for grouping filters
	 */
	
	category = 'dispersed';

	/**
	 * Filter label (e.g. 'What would you like to <b>hunt</b>?')
	 */
	
	label = 'What type of <b>vehicle</b> do you have?';

	/**
	 * Filter options may depend on previously selected filter values
	 * This method lets you return what you want based on previous selections
	 * or return different options based on screen width (e.g. paginated or not)
	 */
	
	getOptions = (data, page, currentFilters, screenWidth) => {
		return [
			{value: '2WD', slug: '2WD'},
			{value: '4WD', slug: '4WD'}
		];
	}
}

export default DispersedVehicleType;