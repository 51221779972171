
const initialState = {
	notifications: []
};

function notificationsReducer(state = initialState, action) {
	let notifications;

	switch (action.type) {
		case 'SET_NOTIFICATIONS':
			return {
				...state,
				notifications: action.payload
			};
		case 'ADD_NOTIFICATION':
			notifications = [
				...state.notifications,
				action.payload
			];

			return {
				...state,
				notifications
			};
		case 'REMOVE_NOTIFICATIONS_OF_TYPE':
			notifications = state.notifications.filter(notification => {
				return (notification.type !== action.payload);
			});

			return {
				...state,
				notifications
			};
		default:
			return state;
	}
}

export default notificationsReducer;