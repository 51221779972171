import React from 'react';
import PropTypes from 'prop-types';
import {
  Router, withRouter,
} from 'react-router-dom';

function stripTrailingSlash(str) {
  if (typeof str === 'string') {
    return str.replace(/\/$/, '')
  }
  return str;
}

function addLeadingSlash(str) {
  if (typeof str === 'string' && str.length > 0 && str[0] !== '/') {
    return `/${str}`
  }
  return str;
}
function hasBasename(path, prefix) {
  return new RegExp('^' + prefix + '(\\/|\\?|#|$)', 'i').test(path);
}
function stripBasename(path, prefix) {
  return hasBasename(path, prefix) ? path.substr(prefix.length) : path;
}

/**
 * needs to override where basename is used
 * as per https://github.com/ReactTraining/react-router/blob/7d64842cea4cfe40bc1c37c131ebd5671ef3c070/packages/react-router/modules/StaticRouter.js
 */
const nestedHistory = (history, bn) => {
  const basename = bn
    ? stripTrailingSlash(addLeadingSlash(bn))
    : '';
  const handlePathObjectAndPerformActionOnHistory = (path, state, action) => {
    if (typeof path === 'object' && 'pathname' in path) {
      return history[action](basename + addLeadingSlash(path.pathname), path.state);
    } else {
      return history[action](basename + addLeadingSlash(path), state);
    }
  }
  return {
    ...history,
    // action: history.action,
    // length: history.length,
    location: {
      ...history.location,
      pathname: stripBasename(history.location.pathname, basename),
    },
    createHref: location => basename + history.createHref(location),
    push: (path, state) => handlePathObjectAndPerformActionOnHistory(path, state, 'push'),
    listen: func => history.listen(location => func({
      ...location,
      pathname: stripBasename(location.pathname, basename),
    })),
    // match: spy(history.match, 'match'),
    replace: (path, state) => handlePathObjectAndPerformActionOnHistory(path, state, 'replace')
    // go: spy(history.go, 'go'),
    // goBack: spy(history.goBack, 'goBack'),
    // goForward: spy(history.goForward, 'goForward'),
    // block: spy(history.block, 'block'),
  };
};

const NestedBrowserRouter = withRouter(class extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.shape({
      createHref: PropTypes.func,
      push: PropTypes.func,
      listen: PropTypes.func,
      replace: PropTypes.func
    }),
    basename: PropTypes.string.isRequired,
  }

  static defaultProps = {
    children: undefined,
    history: undefined,
  }

  constructor(props) {
    super(props);
    this.history = nestedHistory(props.history, props.basename);
  }

  render() {
    const { children } = this.props;
    return <Router history={this.history}>{children}</Router>;
  }
});

export default NestedBrowserRouter;