
export function setSelectedWildlifeState(payload) {
	return {type: 'SET_SELECTED_WILDLIFE_STATE', payload};
}

export function setSelectedWildlifeSpecies(payload) {
	return {type: 'SET_SELECTED_WILDLIFE_SPECIES', payload};
}

export function setSelectedMonth(payload) {
	return {type: 'SET_SELECTED_MONTH', payload};
}

export function setFeatureTypesFromResults(payload) {
	return {type: 'SET_FEATURE_TYPES_FROM_RESULTS', payload};
}

export function setFeatureTypes(payload) {
	return {type: 'SET_FEATURE_TYPES', payload};
}

export function setFeatureTypeCache(payload) {
	return {type: 'SET_FEATURE_TYPE_CACHE', payload};
}