

/**
 * Harvest Prediction / Draw Odds gradient colors
 */

export const huntingGradientColors = [
	'#FF6666',
	'#FF9966',
	'#FFCC66',
	'#FFFF66',
	'#99FF33',
	'#33CC33'
];


/**
 * Column names for various residency fields
 * Values for these fields will be persisted throughout hunt selections
 */

export const residencyFields = [
	'draw_residency',
	'residency'
];