import {styles} from '../explore/config/config';
import {apiBaseUrl} from '../config/config';

const initialState = {
	baseStyle: styles[0],
	camera: {
		zoom: null,
		center: null,
		pitch: null,
		bearing: null
	},
	activity: null,
	initialLoadComplete: false,
	lastStyleUrl: null,
	lastModuleSettings: null
};

function convertStyleUrl(url) {
	const endpoint = url ? url.split('/style')[1] : '/map';
	return `${apiBaseUrl}/style${endpoint}`;
}

function mapSettingsReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_MAP_CAMERA':
			return {
				...state,
				camera: {
					...state.camera,
					...action.payload
				}
			};
		case 'SET_MAP_BASE_STYLE':
			return {
				...state,
				baseStyle: action.payload
			};
		case 'SET_ACTIVITY':
			return {
				...state,
				activity: action.payload
			};
		case 'SET_INITIAL_LOAD_COMPLETE':
			return {
				...state,
				initialLoadComplete: true
			};
		case 'LOAD_PROFILE_SUCCESS': {
			return {
				...state,
				lastStyleUrl: convertStyleUrl(action.payload.style_url)
			};
		}
		case 'SET_LAST_USER_MAPBOX_STYLE_URL': {
			return {
				...state,
				lastStyleUrl: convertStyleUrl(action.payload)
			};	
		}
		case 'SET_LAST_USER_MODULE_SETTINGS': {
			return {
				...state,
				lastModuleSettings: action.payload
			};	
		}
		default:
			return state;
	}
}

export default mapSettingsReducer;