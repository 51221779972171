const initialState = {
	profile: {},
	loaded: false,
	failed: false,
	exploreDestination: '/explore/onboarding?activity=explore'
}

export default function profileReducer(state = initialState, action) {
	switch (action.type) {
		case 'LOAD_PROFILE_SUCCESS': {
			const profile = action.payload;

			let exploreDestination = '/explore';

			const metadata = JSON.parse(profile.metadata);

			if (!metadata || !metadata.hasOwnProperty('onboarding') || !metadata.onboarding.explore) {
				exploreDestination += '/onboarding';
			}

			if (!profile.style_url) {
				exploreDestination += '?activity=explore';
			}

			return {
				...state,
				profile,
				loaded: true,
				failed: false,
				exploreDestination
            }
        }
		case 'LOAD_PROFILE_FAIL':
			return {
				...state,
				failed: true
			};
		case 'LOAD_USER_SUCCESS':
			return {
				...state,
				profile: {
					...state.profile,
					user: action.user
				}
			};
		default:
            return state;
	}
}