import React from 'react';
import './MobileNavTarget.scss';

const MobileNavTarget = props => {
	return (
		<div onClick={props.onClick} className="oa-mobile-nav-target">
			<div className='oa-mobile-nav-target-icon' />
		</div>
	);
}

export default MobileNavTarget;