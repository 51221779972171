import React from "react";
import "./Adventures.scss";
import Activities from "../Activities/Activities";
import GooglePlayBadge from "../../assets/images/google-play-badge.svg";
import AppStoreBadge from "../../assets/images/app_store_badge.svg";
import Arrow from "../../explore/assets/images/icons/chevron.svg";
import Mobile from "../../assets/images/iPhone.png";

const Adventures = (props) => {
    return (
        <div className="oa-adventures-container">
            <div className="oa-adventures-content">
                <h1 dangerouslySetInnerHTML={{ __html: props.headline }} />
                <div className="oa-mobile-container">
                    <div className="oa-mobile">
                        <img
                            className="outly-mobile"
                            alt="Mobile Map"
                            src={Mobile}
                        />
                    </div>
                    <div className="oa-mobile-icon-container">
                        <div className="oa-app-icon-container">
                            <a
                                href="https://apps.apple.com/us/app/outly/id1460401025?mt=8"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="oa-apple-badge"
                                    alt="App Store"
                                    src={AppStoreBadge}
                                />
                            </a>
                        </div>
                        <div className="oa-app-icon-container">
                            <a
                                href="https://play.google.com/store/apps/details?id=com.outsideanalytics.outly"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="oa-google-badge"
                                    alt="Google Play"
                                    src={GooglePlayBadge}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <Activities />
            </div>
            <div className="home-arrow bounce">
                <img src={Arrow} alt="Scroll Arrow" className="arrow" />
            </div>
        </div>
    );
};

export default Adventures;
