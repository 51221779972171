import {createStore, applyMiddleware, combineReducers} from 'redux';  
import thunk from 'redux-thunk';

import staticDataReducer from '../reducers/staticData';
import profileReducer from '../reducers/profile';
import panelReducer from '../reducers/panel';
import activeFeaturesReducer from '../reducers/activeFeatures';
import roadsActivityIndex from '../reducers/roadsActivityIndex';
import mapSettingsReducer from '../reducers/mapSettings';
import authReducer from '../reducers/auth';
import carouselPhotosReducer from '../reducers/carouselPhotos';
import errorReducer from '../reducers/errors';
import pointsReducer from '../reducers/points';
import routesReducer from '../reducers/routes';
import mapsReducer from '../reducers/maps';
import submissionsReducer from '../reducers/submissions';
import myPlacesReducer from '../reducers/myPlaces';
import wildlifeReducer from '../reducers/wildlife';
import huntingReducer from '../reducers/hunting';
import collectionsReducer from '../reducers/collections';
import notificationsReducer from '../reducers/notifications';
import gpxReducer from '../reducers/gpx';
import mapModulesReducer from '../reducers/mapModules';
import mapboxReducer from '../reducers/mapbox';
import infoModalReducer from '../reducers/infoModal';
import modalReducer from '../reducers/modal';

const store = createStore(
	combineReducers({
		staticData: staticDataReducer,
		profile: profileReducer,
		panel: panelReducer,
		activeFeatures: activeFeaturesReducer,
		activeRoadsActivity: roadsActivityIndex,
		mapSettings: mapSettingsReducer,
		auth: authReducer,
		carouselPhotos: carouselPhotosReducer,
		errors: errorReducer,
		points: pointsReducer,
		routes: routesReducer,
		maps: mapsReducer,
		submissions: submissionsReducer,
		myPlaces: myPlacesReducer,
		wildlife: wildlifeReducer,
		hunting: huntingReducer,
		collections: collectionsReducer,
		notifications: notificationsReducer,
		gpx: gpxReducer,
		mapModules: mapModulesReducer,
		mapbox: mapboxReducer,
		infoModal: infoModalReducer,
		modal: modalReducer
	}),
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
	applyMiddleware(thunk)
);


export default store;
