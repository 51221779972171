import React from 'react';
import {connect} from 'react-redux';
import {getProfile} from '../../actions/profile';
import './ActivityFilters.scss';
import Geocoder from '../Geocoder/Geocoder';
import {withRouter} from 'react-router-dom';

class ActivityFilters extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			filters: props.activity ? props.activity.filters : [],
			currentFilterIndex: 0,
			page: 0,
			isActive: props.activity !== null,
			isGeocoder: false,
			location: null
		};

		this.filterListNode = React.createRef();
	}

	componentDidMount(){
		this.token = localStorage.getItem('jwt');
		this.props.getProfile(this.token);
	}

	componentDidUpdate(prevProps) {
		// selecting activity
		if (prevProps.activity === null && this.props.activity !== null) {
			const {activity} = this.props;
			const filters = activity.getFilters(activity.filters);

			this.setState({
				page: 0,
				filters: filters,
				currentFilterIndex: 0,
				isActive: true,
				isGeocoder: !filters.length && activity.hasGeocoder && this.state.location === null,
				location: null
			});
		}

		// deselecting activity (back to activities list)
		if (this.props.activity === null && prevProps.activity !== null) {
			this.setState({
				page: 0,
				currentFilterIndex: 0,
				isActive: false,
				isGeocoder: false,
				location: null
			});
		}

		// switching activities (navigating directly between activity pages)
		if (this.props.activity !== null
				&& prevProps.activity !== null
				&& this.props.activity.name !== prevProps.activity.name) {

			const {activity} = this.props;
			const filters = activity.getFilters(activity.filters);

			this.setState({
				page: 0,
				currentFilterIndex: 0,
				filters: filters,
				isActive: true,
				isGeocoder: !filters.length && activity.hasGeocoder && this.state.location === null,
				location: null
			});
		}
	}

	handleSubmit = async () => {
		/* HERE */
		const url = await this.props.activity.getUrl(
			this.props.profile,
			this.state.location
		);

		this.props.history.push(url);
	}

	setFilterValue(filter, option) {
		if (option.pro && !this.props.profile.has_subscription) {
			// show info modal
			this.props.setInfoModalContent(option.proModalContent);
			return;
		}

		const filters = this.state.filters.reduce((filters, item, index) => {
			// set updated filter value
			if (index === this.state.currentFilterIndex) {
				item.value = option;
			}

			// unset subsequent filter values
			if (index > this.state.currentFilterIndex) {
				item.value = null;
			}

			return [...filters, item];
		}, []);

		this.setState({
			// get filters with updated value
			filters: this.props.activity.getFilters(filters),
			// animate out if sequence not complete
			isActive: this.isSequenceComplete()
		});
	}

	isSequenceComplete() {
		return this.props.activity
			&& this.props.activity.isComplete(
				this.state.filters,
				this.state.location
			);
	}

	handleContainerTransitionEnd = (e) => {
		if (!['transform', '-webkit-transform', '-ms-transform'].includes(e.propertyName)) return;

		const {activity} = this.props;
		const {filters, isActive, currentFilterIndex} = this.state;
		const isComplete = this.isSequenceComplete();

		// reset horizontal scroll position
		this.filterListNode.current.scrollLeft = 0;

		if (!isActive && activity !== null && !isComplete) {

			const nextIndex = currentFilterIndex + 1;
			const isLastFilter = filters.length === nextIndex;

			if (isLastFilter && activity.hasGeocoder) {
				this.setState({
					isGeocoder: true
				});
			}
			
			this.setState({
				currentFilterIndex: nextIndex,
				isActive: true,
				page: 0,
				isSequenceComplete: isComplete
			});
		}
	}

	navigateBack() {
		const index = this.state.currentFilterIndex - 1;

		if (index < 0 && typeof this.props.clearActivity === 'function') { // back to activities list
			this.props.clearActivity();
			return;
		}

		if (this.state.isGeocoder) {
			this.setState({
				page: 0,
				isGeocoder: false
			});
		}

		this.setState({
			currentFilterIndex: index,
			page: 0,
			// unset current and subsequent filter values when navigating back
			filters: this.state.filters.map((filter, f) => {
				if (f >= index) filter.value = null;
				return filter;
			})
		});

		// reset horizontal scroll position
		this.filterListNode.current.scrollLeft = 0;
	}

	setCurrentPage(page) {
		this.setState({page});
	}

	getOptionClassName(filter, option) {
		let className = filter.getOptionClassName(option);

		if (filter.isOptionSelected(option)) {
			className += ' selected';
		}

		return className;
	}

	renderPaginateButton(type) {
		const currentFilter = this.state.filters[this.state.currentFilterIndex];

		if (currentFilter === undefined || currentFilter.perPage === null) {
			return null;
		}

		const hasPrevPage = this.state.page > 0;
		
		const hasNextPage = currentFilter.getOptions(
			this.props.data,
			this.state.page + 1,
			this.state.filters,
			window.innerWidth
		).length > 0;

		if ((type === 'next' && !hasNextPage) || (type === 'prev' && !hasPrevPage)) {
			return null;
		}

		return (
			<li
				className={`paginate-${type}`}
				onClick={() => this.setCurrentPage(this.state.page + (type === 'next' ? 1 : -1))}>
				<svg viewBox="0 0 9 15">
					<polyline strokeLinecap="square" fill="none" stroke="currentColor" strokeWidth="2" transform="translate(7.441247, 7.406587) rotate(-45.000000) translate(-7.441247, -7.406587)" points="11.4412468 3.40658737 3.44124678 3.40658737 3.44124678 11.4065874"></polyline>
				</svg>
			</li>
		);
	}

	renderFilterLabel(filter = {}) {
		if (this.state.isGeocoder && this.props.activity) {
			return (
				<h2 dangerouslySetInnerHTML={{
					__html: this.props.activity.getGeocoderLabel(this.state.filters)
				}} />
			);
		}

		return (
			<h2 dangerouslySetInnerHTML={{__html: filter.label || ''}} />
		);
	}

	renderFilterInfoLink(filter) {
		if (!filter.infoModalContent) {
			return null;
		}

		return (
			<span className='activity-filter-info-link'
				onClick={() => this.props.setInfoModalContent(filter.infoModalContent)}>
				{filter.infoModalContent.label}
			</span>
		);
	}

	render() {
		const filter = this.state.filters[this.state.currentFilterIndex] || {};
		
		const options = typeof filter.getOptions !== 'function'
			? []
			: filter.getOptions(
					this.props.data,
					this.state.page,
					this.state.filters,
					window.innerWidth
				);

		let className = 'activity-filters';

		if (this.state.isActive) {
			className += ' activity-filters-active';
		}

		if (this.state.isGeocoder) {
			className += ' activity-filters-geocoder';
		}

		if (this.isSequenceComplete()) {
			className += ' activity-filters-sequence-complete';
		}

		if (typeof this.props.clearActivity !== 'function' && this.state.currentFilterIndex === 0) {
			className += ' activity-filters-hide-initial-back-button';
		}

		return (
			<div className={className} onTransitionEnd={this.handleContainerTransitionEnd}>
				<button className='back-button' onClick={() => this.navigateBack()}>BACK</button>
				{this.renderFilterLabel(filter)}
				
				<ul ref={this.filterListNode}>
					{this.renderPaginateButton('prev')}
					{options.map((option, o) => (
						<li key={o}
							className={this.getOptionClassName(filter, option)}
							onClick={() => this.setFilterValue(filter, option)}>
							{option.value}
						</li>
					))}
					{this.renderPaginateButton('next')}
				</ul>

				{this.renderFilterInfoLink(filter)}

				<div className='oa-geocoder-container'>
					<Geocoder location={this.state.location} setLocation={location => this.setState({location})} />
				</div>

				<footer className='activity-filters-footer'>
					<button className='submit-button' onClick={this.handleSubmit}>EXPLORE MAP</button>
					<button className='skip-button' onClick={this.handleSubmit}>SKIP TO MAP</button>
				</footer>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	profile: state.profile.profile
});

const mapDispatchToProps = dispatch => ({
	getProfile: (token) => {
		dispatch(getProfile(token));
	}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityFilters));