
/**
 * Activity filter base class
 */

class ActivityFilter {

	/**
	 * Filter slug (query param in resulting url)
	 */
	
	slug = 'paramName';

	/**
	 * Filter category - optional property for grouping filters
	 */
	
	category = null;

	/**
	 * Filter label (e.g. 'What would you like to <b>hunt</b>?')
	 */
	
	label = 'Filter label <b>text</b>?';

	/**
	 * Optional content for the InfoModal component
	 */

	infoModalContent = null;

	/**
	 * Number of filter options to show per page (on desktop)
	 * If null, don't paginate
	 */
	
	perPage = null;

	/**
	 * Filter options getter:
	 * Filter options may depend on previously selected filter values
	 * This method lets you return what you want based on previous selections
	 * or return different options based on screen width (e.g. paginated or not)
	 */
	
	getOptions = (data, page, currentFilters, screenWidth) => {
		return [];
	}

	/**
	 * Determine if a given option is currently selected
	 */

	isOptionSelected(option) {
		if (this.value === null || this.value === undefined) {
			return false;
		}

		return this.value.slug === option.slug;
	}

	/**
	 * Get the classname that should be applied to a given option in the dom
	 */

	getOptionClassName(option) {
		return `filter-option-${option.slug}`;
	}

	/**
	 * Paginate filter options
	 */

	static paginate = (data, page, perPage = 10) => {
		const offset = perPage * page;
		return data.slice(offset, offset + perPage);
	}

	/**
	 * Get filter by slug
	 */
	
	static getFilterBySlug = (filters, slug) => {
		return filters.find(filter => filter.slug === slug);
	}
}

export default ActivityFilter;