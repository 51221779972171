import React from 'react';
import './Footer.scss';
import {NavLink} from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import facebook from '../../assets/icons/FB.svg';
import twitter from '../../assets/icons/Twitter.svg';
import linkedin from '../../assets/icons/LinkedIn.svg';
import instagram from '../../assets/icons/Instagram.svg';
import youtube from '../../assets/icons/youtube.svg';

const Footer = props => (
	<footer className={'oa-footer ' + (props.className || '')} style={{...props.style}}>
		<div className="oa-footer-content">
			<nav className="oa-footer-nav">
				<NavLink to={(props.logoLink) ? props.logoLink : '/'}>
					<img src={logo} alt="Outside Analytics" />
				</NavLink>
				{(props.pageLinks) // case where parent component specifies page links
					? props.pageLinks.map((link, idx) => (
						<NavLink key={idx} to={link.to}>{link.name}</NavLink>
					)) 
					: <>
							<NavLink to="/about-us">About Us</NavLink>
							<NavLink to="/contact">Contact Us</NavLink>
						</>}
				
			</nav>
			<div className="oa-footer-social">
				<a href="https://twitter.com/getoutly" target="_blank" rel="noopener noreferrer">
					<img src={twitter} alt="Twitter" />
				</a>
				<a href="https://www.linkedin.com/company/outside-analytics/" target="_blank" rel="noopener noreferrer">
					<img src={linkedin} alt="LinkedIn" />
				</a>
				<a href="https://www.instagram.com/getoutly" target="_blank" rel="noopener noreferrer">
					<img src={instagram} alt="Instagram" />
				</a>
				<a href="https://www.facebook.com/getOutly" target="_blank" rel="noopener noreferrer">
					<img src={facebook} alt="Facebook" />
				</a>
				<a href="https://www.youtube.com/channel/UC2iqTI80VhjICTH99avUF6Q" target="_blank" rel="noopener noreferrer">
					<img src={youtube} alt="YouTube" />
				</a>
			</div>
		</div>
		<div className="oa-footer-legal">
			<nav>
				{/* NOTICE!!: If you change these to components, you will likely break the links if they are rendered in components that are under the NestedBrowserRouter */}
				<a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
				<a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
				<a href="/cookie-policy" target="_blank" rel="noopener noreferrer">Cookies Policy</a>
			</nav>
			<div className="copyright">
				<span>&copy; {new Date().getFullYear()} Outside Analytics</span>
			</div>
		</div>
	</footer>
);

export default Footer;