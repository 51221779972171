import axios from 'axios';
import {apiBaseUrl} from '../../config/config';
import store from '../../store';
import {setHuntAnalytics} from '../../actions/hunting';


export class HuntingApi {

	/**
	 * Get all analytics
	 */
	
	static async getAnalytics() {
		return axios.get(
			`${apiBaseUrl}/analytics`
		).then(response => {
			const analytics = response.data;
			// cache analytics
			store.dispatch(setHuntAnalytics(analytics));
			return analytics;
		});
	}


	/**
	 * Get all unit types for given state and species
	 */

	static async getUnitTypes(stateID, speciesID) {
		return axios.get(
			`${apiBaseUrl}/unit-layers/${stateID}/${speciesID}`
		).then(response => {
			return response.data;
		});
	}


	static async getHuntParam(stateID, stateSpeciesID, huntType, analytic, sequence, conditions = {}) {
		return axios.post(
			`${apiBaseUrl}/hunting-param`,
			{
				stateID: stateID,
				stateSpeciesID: stateSpeciesID,
				huntType: huntType === 'null' ? null : huntType,
				sequence,
				conditions,
				analytic
			}
		).then(response => {
			if (response.data.hasOwnProperty('sequenceComplete')) {
				throw new SequenceComplete(
					response.data.data,
					response.data.bounds
				);
			}

			return response.data.selection;
		});
	}


	static async getAnalyticData(stateID, stateSpeciesID, huntType, analytic, sequence, conditions) {
		return axios.post(
			`${apiBaseUrl}/hunting-data`,
			{
				stateID: stateID,
				stateSpeciesID: stateSpeciesID,
				huntType: huntType === 'null' ? null : huntType,
				analytic,
				sequence,
				conditions
			}
		).then(response => {
			const results = response.data;

			return {
				data: results.data.data,
				bounds: results.data.bounds
			};
		});
	}


	/**
	 * Get analytic data bounds for state/species/hunt type
	 */
	
	static async getAnalyticDataBounds(stateSpeciesID, huntType) {
		return axios.post(
			`${apiBaseUrl}/hunting-data-bounds`,
			{
				stateSpeciesID,
				huntType: huntType === 'null' ? null : huntType,
			}
		).then(response => {
			return response.data;
		});
	}
}


/**
 * Sequence complete exception
 * Thrown with resolved analytic data when huntParam sequence is complete
 */

export function SequenceComplete(data, bounds) {
	this.status = 'Sequence complete';
	this.data = data;
	this.bounds = bounds;
}


/**
 * Filter hunt params by given analytic
 */

export function getAnalyticHuntParams(huntParams = [], analytic = null) {
	if (analytic === null) return [];

	return huntParams.filter(
		param => param[analytic.hunt_selections_col]
	);
}


/**
 * Filter hunt params by data type (harvest/draw)
 */

export function getDataTypeHuntParams(huntParams = [], dataType = null) {
	if (dataType === null) return [];

	return huntParams.filter(
		param => param[`for_${dataType}`]
	);
}



/**
 * Create conditions object from huntParams, filtered by given analytic 
 * 
 *  {
 *    licenses: { method: 'Archery', sex: 'Bull', etc...},
 *    draw_odds: { draw_residency: 'Resident', etc...}
 *  }
 */

export function getAnalyticConditions(huntParams = [], analytic) {
	return getAnalyticHuntParams(
		huntParams,
		analytic
	).reduce((conditions, param) => {
		conditions[param.selection_source] = {
			...conditions[param.selection_source],
			...param.value
		}

		return conditions;
	}, {
		licenses: {},
		draw_odds: {}
	});
}


/**
 * Get max sequence number in huntParams for given analytic
 */

export function getMaxParamSequence(huntParams = [], analytic) {
	const params = getAnalyticHuntParams(huntParams, analytic);

	if (!params.length) return 1;

	return params[params.length - 1].sequence;
}