import React from 'react';
import './OALanding.scss';
import Butter from 'buttercms';
import ScrollTrigger from '../../components/ScrollTrigger/ScrollTrigger';
import Header from '../../components/Header/Header';
import CompanySplash from '../../components/CompanySplash/CompanySplash';
import CompanySection from '../../components/CompanySection/CompanySection';
import Footer from '../../components/Footer/Footer';
import { getQueryParam } from '../../utils/helperFunctions';

const butter = Butter(process.env.REACT_APP_BUTTER_KEY);

class OALanding extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
			content: null,
			headerTheme: 'dark'
		};
	}

    getPageContent() {
        const previewMode = getQueryParam('preview');

        return butter.page.retrieve(
            '*',
            'company-landing',
            {preview: previewMode}
        ).then(response => {
            return response.data.data.fields;
        });
    }

    async componentDidMount() {
        window.scrollTo(0, 0);

        try {
            const newContent = await this.getPageContent();

            this.setState({
                content: newContent
            });
        } catch (error) {
            console.log(error);
            this.setState({
                content: {}
            });
        }
    }

    handleScrollTrigger = status => {
		const theme = (status.currentPosition === 'above' ? 'dark' : 'light');
		this.setState({headerTheme: theme});
    }
    
    renderStaticContent() {
        const {content} = this.state;

        if (!content) return null;

        return (
            <React.Fragment>
                <CompanySplash {...content} />
                {content.section.map((section, idx) => (
                    (section.show)
                        ? <CompanySection info={section} key={idx} />
                        : null
                ))}
            </React.Fragment>
        );
    }

    render() {
        const footerLinks = [
            {
                name: 'Contact Us',
                to: '/company-contact'
            }
        ]

        return (
            <div className='primary-container'>
                <ScrollTrigger onChange={this.handleScrollTrigger} className='oa-landing-scroll-trigger' />
                <Header theme={this.state.headerTheme} isOA={true} />
                {this.renderStaticContent()}
                <Footer 
                    className='oa-footer-dark landing-footer'
                    pageLinks={footerLinks}
                    logoLink='/company' />
            </div>
        )
    }
}

export default OALanding;