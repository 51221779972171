import React, {useState, useEffect} from 'react';
import './Activities.scss';
import {connect} from 'react-redux';
import axios from 'axios';
import {apiBaseUrl} from '../../config/config';
import {loadStaticDataSuccess} from '../../actions/staticData';
import {setInfoModalContent} from '../../actions/infoModal';
import ActivitiesList from '../ActivitiesList/ActivitiesList';
import ActivityFilters from '../ActivityFilters/ActivityFilters';

import HuntingActivityFilters from '../../activity-filters/hunt';
import CampingActivityFilters from '../../activity-filters/camp';
import FishingActivityFilters from '../../activity-filters/fish';
import RidingActivityFilters from '../../activity-filters/ride';


const Activities = props => {
	const [currentActivity, setActivity] = useState(null);
	
	const activities = {
		camp: new CampingActivityFilters(),
		hunt: new HuntingActivityFilters(),
		ride: new RidingActivityFilters(),
		fish: new FishingActivityFilters()
	};

	// get static data if it hasn't been loaded yet
	useEffect(() => {
		if (!props.staticData.loaded) {
			axios.get(`${apiBaseUrl}/static-data`).then(response => {
				props.setStaticData(response.data);
			});
		}
	}, []);

	// by default, show activities list, hide filters
	const classNames = ['activities-container'];
	// if activity selected, hide list and show filters
	if (currentActivity === null) {
		classNames.push('activities-list-active');
	}

	return (
		<div className={classNames.join(' ')}>
			<h3>Select an activity to start exploring:</h3>
			<ActivitiesList
				activities={activities}
				setActivity={setActivity} />
			<ActivityFilters
				activity={currentActivity}
				clearActivity={() => setActivity(null)}
				profile={props.profile}
				setInfoModalContent={props.setInfoModalContent}
				data={props.staticData} />
		</div>
	);
};

const mapStateToProps = state => ({
	profile: state.profile.profile,
	staticData: state.staticData
});

const mapDispatchToProps = dispatch => ({
	setInfoModalContent: content => {
		dispatch(setInfoModalContent(content));
	},
	setStaticData: data => {
		dispatch(loadStaticDataSuccess(data));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Activities);