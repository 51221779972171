
/**
 * Activity filters base class
 */

class ActivityFilters {

	/**
	 * Activity name (e.g. 'hunt, camp, fish, ride')
	 */
	
	name = 'activity';

	/**
	 * Activity label (e.g. 'Plan my <b>hunt</b>')
	 */
	
	label = 'Activity <b>label</b>';

	/**
	 * Activity panel router endpoint
	 */

	panelDestination = '';

	/**
	 * Activity filters
	 */
	
	filters = [];

	/**
	 * Whether or not to include a geocoder in this filter sequence
	 */
	
	hasGeocoder = false;

	/**
	 * Get label text for geocoder input
	 */
	
	getGeocoderLabel = (currentFilters) => {
		return null;
	}

	/**
	 * Activity filters getter:
	 * Subsequent filters may depend on previously selected filter values
	 * This method lets you return what you want based on current selections
	 */
	
	getFilters = (currentFilters) => {
		return this.filters;
	}

	/**
	 * Determine if this set of filters is complete
	 */
	
	isComplete = (currentFilters= [], location = null) => {
		return currentFilters.every(filter => filter.value)
			&& (!this.hasGeocoder || location !== null);
	}

	/**
	 * Generate a URL for this activity based on selected filter values
	 */
	
	async getUrl(profile, location = null) {
		// base path
		let url = '/explore';

		// panel route
		url += this.isOnboardingComplete(this.name, profile)
			? `/${this.panelDestination}`
			: '/onboarding';

		// activity slug
		url += `?activity=${this.name}`;

		// add filter params - some extensions of this method
		// may need to make database queries, so we define it as async
		url += await this.getFilterParams();

		// add location
		if (location) {
			url += `&lng=${location[0]}&lat=${location[1]}`;
		}

		return url;
	}

	/**
	 * Get url query params for filter values
	 */
	
	async getFilterParams() {
		return this.filters.reduce((url, filter) => {
			if (filter.value) {
				url += `&${filter.slug}=${filter.value.slug}`;
			}

			return url;
		}, '');
	}

	/**
	 * Determine if the user has already seen the onboarding modal for this activity
	 */

	isOnboardingComplete = (slug, profile) => {
		if (profile && profile.metadata) {
			const metadata = JSON.parse(profile.metadata);

			if (metadata.hasOwnProperty('onboarding')) {
				return (metadata.onboarding[slug] === true);
			}
		}

		return false;
	}
}

export default ActivityFilters;