
const initialState = {
	globalError: null
};

function errorReducer(state = initialState, action) {
	switch(action.type) {
		case 'SET_GLOBAL_ERROR':
			return {
				globalError: action.payload
			};
		default:
			return state;
	}
}

export default errorReducer;