import fetch from 'isomorphic-fetch';
import {apiBaseUrl} from '../config/config';

export default class UserApi {
	static getStaticData() {
		return fetch(`${apiBaseUrl}/static-data`, {
			method: 'get'
		}).then(response => {
			return response.json();
		}).catch(error => {
			return error;
		});
	}
}