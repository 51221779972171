

/**
 * Mapbox GL JS Access Token
 */

export const mapboxToken = 'pk.eyJ1IjoiYnJldHRvYSIsImEiOiJjamh1djV2N2YwZW8yM3dwaThjbjdxbXBjIn0.5ZklKePkkraCkPJGhYc-fw';


/**
 * Base URLs
 */

let apiUrl;
switch (process.env.NODE_ENV) {
  case "production":
    apiUrl = "https://prod-api-v125.outsideanalytics.com"; //replaced by Jenkins build script
    break;
  case "oregon":
  case "staging":
    apiUrl = "https://stage-api-host"; //replaced by Jenkins build script
    break;
  case "hostedDevelopment":
    apiUrl = "http://api.outly.localhost";
    break;
  default: // development
    apiUrl = "http://localhost:8000";
}

export const apiBaseUrl = apiUrl;


/**
 * Outly Pro trial period status
 */

export const isProTrialPeriod = true;