const initialState = {
  submissions: [],
  geojson: null
};

function getGeojsonFromSubmissions(points) {
	const geojson = {
		"type": "FeatureCollection"
	};

	if(points) geojson.features = points.reduce((acc, point) => {
		const lng = point.wkb_geometry.coordinates[0];
		const lat = point.wkb_geometry.coordinates[1];

		acc.push({
			"type": "Feature",
			"geometry": {
				"type": "Point",
				"coordinates": [lng, lat]
			},
			"properties": {
				...point
			}
		});

		return acc;
	}, []);

	return geojson;
}

function submissionsReducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_SUBMISSIONS':
      return {
        ...state,
		submissions: action.payload,
		geojson: getGeojsonFromSubmissions(action.payload)
      };
    default:
      return state;
  }
}

export default submissionsReducer;